import React from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Info } from "@material-ui/icons";

const UpdateFrequencyToggle = ({ handleClick }) => {
  return (
    <Box
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      position="absolute"
      zIndex={1}
      bottom={70}
      right={49}
      display="flex"
      flexDirection="column"
      width={160}
    >
      <Button
        onClick={handleClick}
        color="secondary"
        variant="contained"
        size="small"
        startIcon={<Info />}
      >
        Update Frequency
      </Button>
    </Box>
  );
};

export default UpdateFrequencyToggle;
