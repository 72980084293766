import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import useFetchData from "../../../../hooks/useFetchData";
import { convertUTCDateToLocalDate, groupByValue } from "../../../../utils";
import { useApp } from "../../../../AppProvider";

const useGraphMode = ({
  map,
  updateLayerFilters,
  updateLayerStyles,
  layers,
  lastLocationIdClicked,
  setLastLocationIdClicked,
  setDataVizVisible,
  graphModeVisible,
  setGraphModeVisible,
  setFilterValues,
}) => {
  const { doToast } = useApp();

  const [legendVisible, setLegendVisible] = useState(true);
  const [lastLocationId, setLastLocationId] = useState(null);
  const [inputValue, setInputValue] = useState(0);

  const initFilterValues = {
    startDate: null,
    endDate: null,
    analysis: "benchmark_scale_median",
    season: "0",
    parameterCategories: [],
    threats: [],
    priorityTypes: [],
    parameters: [],
    recordCount: 0,
  };
  const [filterValuesGraphMode, setFilterValuesGraphMode] =
    useState(initFilterValues);

  const analysisTypes = [
    {
      value: "benchmark_scale_median",
      label: "Median",
    },
    {
      value: "benchmark_scale_pctile85",
      label: "85th Percentile",
    },
  ];

  const seasons = [
    {
      value: "0",
      label: "Annual",
    },
    {
      value: "1",
      label: "Low Flow",
    },
    {
      value: "2",
      label: "Shoulder: Low to High",
    },
    {
      value: "3",
      label: "High Flow",
    },
    {
      value: "4",
      label: "Shoulder: High to Low",
    },
  ];

  const [startDate] = useFetchData(`analysis-start-date`, [], false);
  const [endDate] = useFetchData(`analysis-end-date`, [], false);

  useEffect(() => {
    if (startDate.length > 0 && endDate.length > 0) {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          startDate: convertUTCDateToLocalDate(
            new Date(startDate[0].analysis_start_date)
          ),
          endDate: convertUTCDateToLocalDate(
            new Date(endDate[0].analysis_end_date)
          ),
        };
      });
    }
  }, [startDate, endDate]);

  const [parameterCategories] = useFetchData(
    "list-parameter-categories-graph-mode",
    [],
    false
  );

  const [priorityTypes] = useFetchData(
    "list-priority-types-graph-mode",
    [],
    false
  );

  const [threats] = useFetchData("list-threats-graph-mode", [], false);

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (parameterCategories.length && priorityTypes.length && threats.length) {
      onSelectAllParameterCategories();
      onSelectAllPriorityTypes();
      onSelectAllThreats();
      setHasFiltersLoaded(true);
    }
  }, [parameterCategories, priorityTypes, threats]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    [
      "list-parameters-graph-mode",
      filterValuesGraphMode.parameterCategories,
      filterValuesGraphMode.priorityTypes,
      filterValuesGraphMode.threats,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-parameters-graph-mode`,
            {
              parameterCategories:
                filterValuesGraphMode.parameterCategories.map(
                  (parameterCategory) =>
                    getParameterCategoryIndexByName(parameterCategory)
                ),
              priorityTypes: filterValuesGraphMode.priorityTypes.map(
                (priorityType) => getPriorityTypeIndexByName(priorityType)
              ),
              threats: filterValuesGraphMode.threats.map((threat) =>
                getThreatIndexByName(threat)
              ),
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (parameters?.length && !hasParametersLoaded) {
      setHasParametersLoaded(true);
      onSelectAllParameters();
    }
  }, [parameters]); //eslint-disable-line

  const [benchmarkScaleColors] = useFetchData(
    "list-benchmark-scale-colors",
    [],
    false
  );
  const getHexColorForScore = (score) => {
    return (
      benchmarkScaleColors.find((x) => x.benchmark_scale === score)
        ?.symbol_color || "black"
    );
  };

  const graphModeBenchmarkColorsDefaults = [
    { name: `Parameter not detected`, color: `#228044` },
    { name: `Below benchmark`, color: `#0EF465` },
    { name: `Approaching benchmark`, color: `yellow` },
    { name: `Above benchmark`, color: `orange` },
    { name: `Above secondary benchmark`, color: `red` },
    { name: `No benchmarks available`, color: `cornflowerblue` },
  ];
  const [graphModeBenchmarkColors, setGraphModeBenchmarkColors] = useState(
    graphModeBenchmarkColorsDefaults
  );
  useEffect(() => {
    if (benchmarkScaleColors) {
      setGraphModeBenchmarkColors([
        {
          name: `Parameter not detected`,
          color: benchmarkScaleColors[1]?.symbol_color ?? `#228044`,
        },
        {
          name: `Below benchmark`,
          color: benchmarkScaleColors[2]?.symbol_color ?? `##0EF465`,
        },
        {
          name: `Approaching benchmark`,
          color: benchmarkScaleColors[3]?.symbol_color ?? `yellow`,
        },
        {
          name: `Above benchmark`,
          color: benchmarkScaleColors[4]?.symbol_color ?? `orange`,
        },
        {
          name: `Above secondary benchmark`,
          color: benchmarkScaleColors[5]?.symbol_color ?? `red`,
        },
        {
          name: `No benchmarks available`,
          color: benchmarkScaleColors[0]?.symbol_color ?? `cornflowerblue`,
        },
      ]);
    }
  }, [benchmarkScaleColors]);

  const [graphModePopupVisible, setGraphModePopupVisible] = useState(true);
  const [graphModeLayersVisible, setGraphModeLayersVisible] = useState(true);
  const handleGraphModeLayersToggleClick = () => {
    if (graphModeLayersVisible) {
      layers.forEach((layer) => {
        if (
          [
            "denver-water-locations-circle",
            "denver-water-locations-symbol",
          ].includes(layer.id)
        ) {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    } else {
      layers.forEach((layer) => {
        if (layer?.layout?.visibility === "visible") {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    }
    setGraphModeLayersVisible(!graphModeLayersVisible);
  };

  const handleGraphModeClick = () => {
    if (!graphModeVisible) {
      // layers.forEach((layer) => {
      //   if (
      //     ["denver-water-locations-circle", "denver-water-locations-symbol"].includes(
      //       layer.id
      //     )
      //   ) {
      //     map.setLayoutProperty(
      //       layer?.lreProperties?.name || layer.id,
      //       "visibility",
      //       "visible"
      //     );
      //   } else {
      //     map.setLayoutProperty(
      //       layer?.lreProperties?.name || layer.id,
      //       "visibility",
      //       "none"
      //     );
      //   }
      // });
      setDataVizVisible(true);
      // map.setFilter("denver-water-locations-circle", null);
      // map.setFilter("denver-water-locations-symbol", null);
    } else {
      // map.setFilter("denver-water-locations-circle", null);
      // map.setFilter("denver-water-locations-symbol", null);

      //mjb what is updateLayerFilters doing and why is it removing points
      // updateLayerFilters(filterValues);
      setFilterValues((prevState) => {
        const newState = {
          ...prevState,
          graphMode: {
            ...prevState["graphModeReference"],
          },
        };
        updateLayerFilters(newState);
        return newState;
      });

      updateLayerStyles({
        id: "default",
        layerId: "denver-water-locations-circle",
        layerFieldName: "",
        name: "Default",
        paint: {
          "circle-color": "#1e8dd2",
          "circle-stroke-width": 2,
          "circle-stroke-color": "black",
        },
      });
      setDataVizVisible(false);

      layers.forEach((layer) => {
        if (layer?.layout?.visibility === "visible") {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    }
    // handleFilterValuesGraphMode("recordCount", 0);
    // handleFilterValuesGraphMode("season", "0");
    // handleFilterValuesGraphMode("startDate", null);
    // handleFilterValuesGraphMode("endDate", null);
    setInputValue(0);
    setGraphModePopupVisible(true);
    map.fire("closeAllPopups");
    setLastLocationIdClicked(null);
    setLastLocationId(null);
    setGraphModeVisible(!graphModeVisible);
    setGraphModeLayersVisible(true);
  };

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          parameters: cleanParams(filterValuesGraphMode.parameters),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          parameterCategories: cleanParamCategories(
            filterValuesGraphMode.parameterCategories
          ),
          priorityTypes: cleanPriorityTypes(
            filterValuesGraphMode.priorityTypes
          ),
          threats: cleanThreats(filterValuesGraphMode.threats),
        };
      });
    }
  }, [parameterCategories, priorityTypes, threats]); //eslint-disable-line

  const [hasGraphDataLoaded, setHasGraphDataLoaded] = useState(false);
  const [isAnalyticsTableDataLoading, setIsAnalyticsTableDataLoading] =
    useState(false);
  const { data, refetch } = useQuery(
    [
      "locations-map-graph-mode",
      filterValuesGraphMode.recordCount,
      filterValuesGraphMode.parameters,
      hasParametersLoaded,
    ],
    async () => {
      if (hasParametersLoaded) {
        setIsAnalyticsTableDataLoading(true);
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/locations-map-graph-mode`,
            {
              recordCount: filterValuesGraphMode.recordCount,
              parameters: filterValuesGraphMode.parameters.map((parameter) =>
                getParameterIndexByName(parameter)
              ),
            }
          );

          setHasGraphDataLoaded(true);
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleControlsSubmit = () => {
    async function send() {
      try {
        setIsSubmitLoading(true);
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/analysis-date-trigger`,
          {
            startDate: filterValuesGraphMode.startDate,
            endDate: filterValuesGraphMode.endDate,
          }
        );

        doToast(
          "info",
          "Records are updating. This may take several minutes. You can continue to use the map while this is happening. The map will update automatically when the records are finished updating."
        );
        let count = 0;
        let timeoutHandle = () => {
          let status = "";
          const fetchNewDate = async () => {
            const response = await axios.get(
              `${process.env.REACT_APP_ENDPOINT}/api/dynamic-stats-trigger-status`
            );
            status = response.data;
          };
          fetchNewDate();

          setTimeout(() => {
            if (status.status === "running") {
              //give fail notice if the request takes longer than 3 minutes
              if (count > 36) {
                console.error(
                  "Query taking longer than normal. Timeout invoked."
                );
                setIsSubmitLoading(false);
                doToast(
                  "error",
                  "Query taking longer than normal. Timeout invoked."
                );
                return;
              }
              count++;
              timeoutHandle();
            } else {
              doToast("success", "Records were updated successfully.");
              refetch();
              setIsSubmitLoading(false);
            }
          }, 5000);
        };
        timeoutHandle();
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          doToast("error", "Update was canceled.");
          setIsSubmitLoading(false);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong.";
          doToast("error", message);
          setIsSubmitLoading(false);
        }
      }
    }
    send();
  };

  useEffect(() => {
    if (graphModeVisible) {
      setIsAnalyticsTableDataLoading(false);
      recolorPointsForLayers(data);
      fetchAnalyticsTableForLocation();
      fetchAnalyticsTimeSeriesForLocation();
    }
  }, [graphModeVisible, data]); //eslint-disable-line

  useEffect(() => {
    if (graphModeVisible) {
      recolorPointsForLayers(data);
    }
  }, [filterValuesGraphMode.analysis, filterValuesGraphMode.season]); //eslint-disable-line

  const recolorPointsForLayers = (data = null) => {
    const layerIds = ["denver-water-locations-circle"];

    // sort by location_index ascending
    data.sort((a, b) => (a.ndx > b.ndx ? 1 : b.ndx > a.ndx ? -1 : 0));

    const colorData = [];
    const locationValues = {};
    const benchmark =
      "s" + filterValuesGraphMode.season + "_" + filterValuesGraphMode.analysis;

    data.forEach((row) => {
      // set a default score
      if (
        typeof locationValues[row.ndx] === "undefined" &&
        row[benchmark] !== null
      ) {
        locationValues[row.ndx] = -1;
      }

      if (row[benchmark] !== null) {
        if (row[benchmark] > locationValues[row.ndx]) {
          locationValues[row.ndx] = row[benchmark];
        }
      }
    });

    for (const [loc_id, score] of Object.entries(locationValues)) {
      colorData.push(parseInt(loc_id));
      colorData.push(getHexColorForScore(score));
    }

    setFilterValues((prevState) => {
      const newState = {
        ...prevState,
        graphMode: {
          ...prevState["graphMode"],
          value: Object.keys(locationValues).map((x) => parseInt(x)).length
            ? Object.keys(locationValues).map((x) => parseInt(x))
            : "",
        },
      };
      updateLayerFilters(newState);
      return newState;
    });

    layerIds.forEach((id) => {
      if (colorData.length) {
        map.setPaintProperty(id, "circle-stroke-width", 2);
        map.setPaintProperty(id, "circle-stroke-color", "black");
        map.setPaintProperty(id, "circle-opacity", 1);
        map.setPaintProperty(id, "circle-stroke-opacity", 1);
        map.setPaintProperty(id, "circle-color", [
          "interpolate",
          ["linear"],
          ["get", "ndx"],
          ...colorData,
        ]);
      }
    });
  };

  const getParameterCategoryIndexByName = (name) => {
    let parameterCategory = parameterCategories?.find(
      (x) => x.param_category_desc === name
    );
    return parameterCategory.param_category_index;
  };

  const getPriorityTypeIndexByName = (name) => {
    let priorityType = priorityTypes?.find(
      (x) => x.priority_type_desc === name
    );
    return priorityType.priority_type_index;
  };

  const getThreatIndexByName = (name) => {
    let threat = threats?.find((x) => x.threat_desc === name);
    return threat.threat_index;
  };

  const getParameterIndexByName = (name) => {
    let parameter = parameters.find((x) => x.parameter_abbrev === name);
    return parameter?.parameter_index || null;
  };

  const cleanParams = (params) => {
    const newParams = [];

    parameters.forEach((x) => {
      if (params.indexOf(x.parameter_abbrev) !== -1) {
        newParams.push(x.parameter_abbrev);
      }
    });

    return newParams;
  };

  const cleanParamCategories = (params) => {
    const newParams = [];

    parameterCategories.forEach((x) => {
      if (params.indexOf(x.param_category_desc) !== -1) {
        newParams.push(x.param_category_desc);
      }
    });

    return newParams;
  };

  const cleanPriorityTypes = (params) => {
    const newParams = [];

    priorityTypes.forEach((x) => {
      if (params.indexOf(x.priority_type_desc) !== -1) {
        newParams.push(x.priority_type_desc);
      }
    });

    return newParams;
  };

  const cleanThreats = (params) => {
    const newParams = [];

    threats.forEach((x) => {
      if (params.indexOf(x.threat_desc) !== -1) {
        newParams.push(x.threat_desc);
      }
    });

    return newParams;
  };

  const handleFilterValuesGraphMode = (name, value) => {
    if (
      ![
        "analysis",
        "season",
        "recordCount",
        "startDate",
        "endDate",
        "parameterCategories",
        "priorityTypes",
        "threats",
      ].includes(name)
    ) {
      setFilterValuesGraphMode((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(value);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else if (
      ["parameterCategories", "priorityTypes", "threats"].includes(name)
    ) {
      setFilterValuesGraphMode((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const onSelectAllParameters = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameters: parameters.map((x) => x.parameter_abbrev),
      };
    });
  };

  const onSelectNoneParameters = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameters: [],
      };
    });
  };

  const onSelectAllParameterCategories = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameterCategories: parameterCategories.map(
          (x) => x.param_category_desc
        ),
      };
    });
  };

  const onSelectAllPriorityTypes = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        priorityTypes: priorityTypes.map((x) => x.priority_type_desc),
      };
    });
  };

  const onSelectAllThreats = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        threats: threats.map((x) => x.threat_desc),
      };
    });
  };

  useEffect(() => {
    if (graphModeVisible !== null) {
      onSelectAllParameterCategories();
      onSelectAllPriorityTypes();
      onSelectAllThreats();
      onSelectAllParameters();
    }
  }, [graphModeVisible]); //eslint-disable-line

  const [analyticsResults, setAnalyticsResults] = useState(null);
  const [timeSeriesResults, setTimeSeriesResults] = useState(null);

  function fetchAnalyticsTableForLocation(location_index) {
    if (graphModeVisible) {
      if (!location_index) location_index = lastLocationId;
      if (!location_index) return;
      setAnalyticsResults(data.filter((item) => item.ndx === location_index));
    }
  }

  const [isTimeSeriesResultsLoading, setIsTimeSeriesResultsLoading] =
    useState(false);
  function fetchAnalyticsTimeSeriesForLocation(location_index) {
    setIsTimeSeriesResultsLoading(true);
    if (graphModeVisible) {
      if (!location_index) location_index = lastLocationId;
      if (!location_index) return;

      async function send() {
        try {
          const { data: line } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-table-for-map-display/${location_index}`,
            {
              parameters: cleanParams(filterValuesGraphMode.parameters).map(
                (x) => getParameterIndexByName(x)
              ),
              startDate: filterValuesGraphMode.startDate,
              endDate: filterValuesGraphMode.endDate,
            }
          );

          const groupedLineArray = groupByValue(line, "parameter_index");

          const { data: bar } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-annual-table-for-map-display/${location_index}`,
            {
              parameters: cleanParams(filterValuesGraphMode.parameters).map(
                (x) => getParameterIndexByName(x)
              ),
              startYear: filterValuesGraphMode.startDate.getFullYear(),
              endYear: filterValuesGraphMode.endDate.getFullYear(),
            }
          );

          const groupedBarArray = groupByValue(bar, "parameter_index");

          // console.log("line", groupedLineArray);
          // console.log("bar", groupedBarArray);

          setTimeSeriesResults({
            line: groupedLineArray,
            bar: groupedBarArray,
          });
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }

      send().then(() => {});
    }
  }

  useEffect(() => {
    setIsTimeSeriesResultsLoading(false);
  }, [timeSeriesResults]);

  useEffect(() => {
    setLastLocationId(lastLocationIdClicked);
    fetchAnalyticsTableForLocation(lastLocationIdClicked);
    fetchAnalyticsTimeSeriesForLocation(lastLocationIdClicked);
  }, [lastLocationIdClicked]); //eslint-disable-line

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = (index) => {
    if (index !== 3) return;
    setIsExportLoading(true);
    async function send() {
      try {
        if (index === 3) {
          let tableData = [...data];
          const tableDataCsvString = [
            [
              `"Results for parameters: ${filterValuesGraphMode.parameters.join(
                ", "
              )}"`,
            ],
            [`"Stats & Benchmarks Data"`],
            [
              "Location ID",
              "Location Name",
              "Location Type",
              "Parameter Category",
              "Parameter",

              "AllBaselineTrend",
              "LowBaselineTrend",
              "LowtoHighBaselineTrend",
              "HighBaselineTrend",
              "HightoLowBaselineTrend",

              "AllLongtermTrend",
              "LowLongtermTrend",
              "LowtoHighLongtermTrend",
              "HighLongtermTrend",
              "HightoLowLongtermTrend",

              "AllCount",
              "LowCount",
              "LowtoHighCount",
              "HighCount",
              "HightoLowCount",

              "All85",
              "Low85",
              "LowtoHigh85",
              "High85",
              "HightoLow85",

              "AllMed",
              "LowMed",
              "LowtoHighMed",
              "HighMed",
              "HightoLowMed",

              "BAll85",
              "BLow85",
              "BLowtoHigh85",
              "BHigh85",
              "BHightoLow85",

              "BAllM",
              "BLowM",
              "BLowtoHighM",
              "BHighM",
              "BHightoLowM",

              "Benchmark 0",
              "Benchmark 1",
              "Benchmark 2",
              "Benchmark 3",
              "Benchmark 4",

              "Basin",
              "Units",

              "BenchmarkStart",
              "BenchmarkEnd",
              "FullPoRStart",
              "FullPoREnd",

              "Organizations",
              "Sources",
            ],
            ...tableData.map((item) => [
              item.location_id.replaceAll(",", "."),
              item.location_name?.replaceAll(",", ".") || "N/A",
              item.loc_type,
              item.param_category,
              item.parameter.replaceAll(",", "."),

              item.trend_dynamic,
              item.s1_trend_dynamic,
              item.s2_trend_dynamic,
              item.s3_trend_dynamic,
              item.s4_trend_dynamic,

              item.trend_longterm,
              item.s1_trend_longterm,
              item.s2_trend_longterm,
              item.s3_trend_longterm,
              item.s4_trend_longterm,

              item.recordcount,
              item.s1_recordcount,
              item.s2_recordcount,
              item.s3_recordcount,
              item.s4_recordcount,

              item.s0_pctile85,
              item.s1_pctile85,
              item.s2_pctile85,
              item.s3_pctile85,
              item.s4_pctile85,

              item.s0_median,
              item.s1_median,
              item.s2_median,
              item.s3_median,
              item.s4_median,

              item.s0_benchmark_scale_pctile85,
              item.s1_benchmark_scale_pctile85,
              item.s2_benchmark_scale_pctile85,
              item.s3_benchmark_scale_pctile85,
              item.s4_benchmark_scale_pctile85,

              item.s0_benchmark_scale_median,
              item.s1_benchmark_scale_median,
              item.s2_benchmark_scale_median,
              item.s3_benchmark_scale_median,
              item.s4_benchmark_scale_median,

              item.bmk_line0,
              item.bmk_line1,
              item.bmk_line2,
              item.bmk_line3,
              item.bmk_line4,

              item.basin,
              item.units,

              item.por_start,
              item.por_end,
              item.fullpor_start,
              item.fullpor_end,

              item.organizations.join(" + "),
              item.sources.join(" + "),
            ]),
          ]
            .map((e) => e.join(","))
            .join("\n");

          const a = document.createElement("a");
          a.href =
            "data:attachment/csv," + encodeURIComponent(tableDataCsvString);
          a.target = "_blank";
          a.download = `Stats & Benchmarks Data.csv`;
          document.body.appendChild(a);
          a.click();
        }
        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };

  return {
    filterValuesGraphMode,
    analysisTypes,
    seasons,
    parameterCategories,
    priorityTypes,
    threats,
    parameters,
    handleFilterValuesGraphMode,
    onSelectAllParameters,
    onSelectNoneParameters,
    handleGraphModeClick,
    hasGraphDataLoaded,
    analyticsResults,
    timeSeriesResults,
    isTimeSeriesResultsLoading,
    getHexColorForScore,
    isAnalyticsTableDataLoading,
    legendVisible,
    setLegendVisible,
    graphModeBenchmarkColors,
    handleGraphModeLayersToggleClick,
    graphModeLayersVisible,
    graphModePopupVisible,
    setGraphModePopupVisible,
    inputValue,
    setInputValue,
    handleExportClick,
    handleControlsSubmit,
    isSubmitLoading,
    isExportLoading,
  };
};

export default useGraphMode;
