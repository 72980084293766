import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SubmitIcon from "@material-ui/icons/Publish";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useDebounce from "../../../hooks/useDebounce";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Loader from "../../../components/Loader";
import { convertUTCDateToLocalDate } from "../../../utils";
import { RefreshCw } from "react-feather";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const TextField = styled(MuiTextField)`
  width: 150px;
  min-width: 125px;
  display: flex;
`;

const FiltersBarGraphMode = ({
  filterValues,
  handleFilterValues,
  analysisTypes,
  seasons,
  inputValue,
  setInputValue,
  handleControlsSubmit,
  isSubmitLoading,
}) => {
  const handleInput = (event) => {
    const { value } = event.target;
    setInputValue(+value);
  };

  const debouncedSearchValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    handleFilterValues("recordCount", debouncedSearchValue);
  }, [debouncedSearchValue]); //eslint-disable-line

  return (
    <>
      <FiltersSection>
        <FiltersContainer>
          <TextField
            variant="outlined"
            select
            fullWidth
            size="small"
            label="Analysis"
            value={filterValues.analysis}
            onChange={(e) => handleFilterValues("analysis", e.target.value)}
          >
            {analysisTypes.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            select
            fullWidth
            size="small"
            label="Flow Regime"
            value={filterValues.season}
            onChange={(e) => handleFilterValues("season", e.target.value)}
          >
            {seasons.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>

          <FormControl variant="outlined">
            <InputLabel htmlFor="record-count">Min Records</InputLabel>
            <OutlinedInput
              style={{ width: "120px" }}
              type="number"
              autoComplete="off"
              variant="outlined"
              margin="dense"
              label="Record Count"
              value={inputValue}
              onChange={handleInput}
            />
          </FormControl>
        </FiltersContainer>
      </FiltersSection>

      <FiltersSection>
        <FiltersContainer>
          <Tooltip title="Quick-set dates to baseline study period">
            <IconButton
              color="primary"
              aria-label="reset to baseline dates"
              onClick={() => {
                handleFilterValues(
                  "startDate",
                  convertUTCDateToLocalDate(new Date("10/01/11"))
                );
                handleFilterValues(
                  "endDate",
                  convertUTCDateToLocalDate(new Date("9/30/21"))
                );
              }}
            >
              <RefreshCw />
            </IconButton>
          </Tooltip>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl>
              <KeyboardDatePicker
                style={{ width: "160px" }}
                size="small"
                autoOk
                inputVariant="outlined"
                disableToolbar
                format="MM/dd/yyyy"
                id="start-date-picker"
                label="Start Date"
                value={filterValues.startDate}
                onChange={(date) => handleFilterValues("startDate", date)}
                InputAdornmentProps={{
                  "aria-label": "change start date",
                }}
              />
            </FormControl>

            <FormControl>
              <KeyboardDatePicker
                style={{ width: "160px" }}
                size="small"
                autoOk
                inputVariant="outlined"
                disableToolbar
                format="MM/dd/yyyy"
                id="end-date-picker"
                label="End Date"
                value={filterValues.endDate}
                onChange={(date) => handleFilterValues("endDate", date)}
                InputAdornmentProps={{
                  "aria-label": "change end date",
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
          <Tooltip
            placement="bottom"
            title={
              <Box display="flex" justifyContent="center" alignItems="center">
                <InfoIcon color="primary" />
                <Typography
                  component="span"
                  variant="h6"
                  style={{ textAlign: "center" }}
                >
                  Click to submit dates to recalculate benchmark statistics
                </Typography>
                <InfoIcon color="primary" />
              </Box>
            }
            arrow
          >
            <span>
              {isSubmitLoading ? (
                <Loader />
              ) : (
                <IconButton
                  color="secondary"
                  aria-label="delete"
                  onClick={handleControlsSubmit}
                >
                  <SubmitIcon />
                </IconButton>
              )}
            </span>
          </Tooltip>
        </FiltersContainer>
      </FiltersSection>
    </>
  );
};

export default FiltersBarGraphMode;
