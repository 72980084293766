/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Database,
  FileText,
  Home,
  Hash,
  Monitor,
  Users,
  Download,
  Map as MapIcon,
  Droplet,
  Thermometer,
  Target,
} from "react-feather";

import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import { CrudProvider } from "../CrudProvider";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import Default from "../pages/dashboards/Default";
import AuthGuard from "../components/AuthGuard";
import WaterQualityReportCard from "../pages/dataAccess/Reports/WaterQualityReportCard";
import QueryAndDownload from "../pages/dataAccess/QueryAndDownload/QueryAndDownload";
import ReservoirPortalTool from "../pages/dataAccess/iFrame/ReservoirPortalTool";
import BenchmarkDocumentation from "../pages/Resources/iFrame/BenchmarkDocumentation";

import { Class, ScatterPlot, Timeline } from "@material-ui/icons";
import TimeSeriesComparisons from "../pages/dataAccess/TimeSeriesComparisons/TimeSeriesComparisons";
import TemperatureVsFlow from "../pages/dataAccess/TemperatureVsFlow/TemperatureVsFlow";
import ParameterComparisonScatterPlot from "../pages/dataAccess/ParameterComparisonScatterPlot/ParameterComparisonScatterPlot";
import SiteStatistics from "../pages/dataAccess/SiteStatistics/SiteStatistics";
import SiteComparisons from "../pages/dataAccess/SiteComparisons/SiteComparisons";
import Blank from "../pages/pages/Blank";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: item.sidebarName ?? inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

const getCrudRoutes = (list) => {
  return list.map((item) => {
    const config = require(`../pages/models/${item.name}Config`);
    const slug = inflector.dasherize(inflector.underscore(item.name));

    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      component: CrudIndexPage,
      provider: CrudProvider,
      config,
      crud: [
        {
          path: `/models/${slug}/:id`,
          name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
        {
          path: `/models/${slug}/add`,
          name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
      ],
    };
  });
};

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

const publicMapRoutes = {
  header: "Data Access",
  id: "Map Explorer & Graph Mode",
  icon: <MapIcon />,
  path: "/data-access/map-explorer",
  name: "Map Explorer & Graph Mode",
  component: PublicMap,
  guard: AuthGuard,
};
//
// const dataAccessRoutes = {
//   id: "Graph Explorer",
//   icon: <Activity />,
//   path: "/data-access/graph-explorer",
//   name: "Graph Explorer",
//   component: Blank,
//   guard: AuthGuard,
// };

const queryAndDownloadRoutes = {
  id: "Query & Download",
  icon: <Download />,
  path: "/data-access/query-and-download",
  name: "Query and Download",
  component: QueryAndDownload,
  guard: AuthGuard,
};

const reportsRoutes = {
  id: "Water Quality Report Card",
  icon: <FileText />,
  path: "/data-access/water-quality-report-card",
  name: "Water Quality Report Card",
  component: WaterQualityReportCard,
  guard: AuthGuard,
};

const reservoirPortalToolRoute = {
  id: "Reservoir Portal Tool",
  icon: <Droplet />,
  path: "/data-access/reservoir-portal-tool",
  name: "Reservoir Portal Tool",
  component: ReservoirPortalTool,
  guard: AuthGuard,
};

const timeSeriesComparisonsRoute = {
  id: "Time Series Comparisons",
  icon: <Timeline />,
  path: "/data-access/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparisons,
  guard: AuthGuard,
};

const parameterComparisonScatterPlotRoute = {
  id: "Parameter Comparison Scatter Plot",
  icon: <ScatterPlot />,
  path: "/data-access/parameter-comparison-scatter-plot",
  name: "Parameter Comparison Scatter Plot",
  component: ParameterComparisonScatterPlot,
  guard: AuthGuard,
};

const TemperatureVsFlowRoute = {
  id: "Stream Temp & Air Temp vs. Flow",
  icon: <Thermometer />,
  path: "/data-access/stream-temp-and-air-temp-vs-flow",
  name: "Stream Temp and Air Temp vs. Flow",
  component: TemperatureVsFlow,
  guard: AuthGuard,
};

const siteStatisticsRoute = {
  id: "Site Statistics",
  icon: <Hash />,
  path: "/data-access/site-statistics",
  name: "Site Statistics",
  component: SiteStatistics,
  guard: AuthGuard,
};

const siteComparisonRoute = {
  id: "Site Comparisons",
  icon: <Target />,
  path: "/data-access/site-comparisons",
  name: "Site Comparisons",
  component: SiteComparisons,
  guard: AuthGuard,
};

const resourcesRoutes = {
  header: "Resources",
  id: "Benchmark Documentation",
  icon: <Droplet />,
  path: "/resources/benchmark-documentation",
  name: "Benchmark Documentation",
  component: BenchmarkDocumentation,
  guard: AuthGuard,
};

const tutorialsRoutes = {
  id: "How To ...",
  icon: <Class />,
  children: [
    {
      externalLink: true,
      id: "Explore Water Quality",
      path: "https://youtu.be/wydD_5M884E",
      name: "Explore Water Quality",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Query & Download Data",
      path: "https://youtu.be/H9pxzZwLGDM",
      name: "Query and Download Data",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Create a Water Quality Report Card",
      path: "https://youtu.be/rkCtb7tWRWQ",
      name: "Create a Water Quality Report Card",
      component: Blank,
    },
    {
      externalLink: true,
      id: "View Time Series Comparisons",
      path: "https://youtu.be/UHPF4-gItFM",
      name: "View Time Series Comparisons",
      component: Blank,
    },
  ],
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  guard: AuthGuard,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  header: "Dashboards",
  id: "Water Quality Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  // dataAccessRoutes,
  queryAndDownloadRoutes,
  reportsRoutes,
  accountRoutes,
  reservoirPortalToolRoute,
  timeSeriesComparisonsRoute,
  parameterComparisonScatterPlotRoute,
  TemperatureVsFlowRoute,
  siteStatisticsRoute,
  siteComparisonRoute,
  resourcesRoutes,
  tutorialsRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  ...modelCrudRoutes,
  publicMapRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  ...crudSidebarMenu,
  publicMapRoutes,
  // dataAccessRoutes,
  queryAndDownloadRoutes,
  reportsRoutes,
  reservoirPortalToolRoute,
  timeSeriesComparisonsRoute,
  parameterComparisonScatterPlotRoute,
  TemperatureVsFlowRoute,
  siteStatisticsRoute,
  siteComparisonRoute,
  resourcesRoutes,
  tutorialsRoutes,
];
