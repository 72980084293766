import styled from "styled-components/macro";

export const DisclaimerText = styled("p")`
  background-color: #ffece6;
  border: #a61010 solid 1px;
  border-radius: 4px;
  color: #a61010;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
`;
