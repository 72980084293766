import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled from "styled-components/macro";
import debounce from "lodash.debounce";
import ResetZoomControl from "../../map/ResetZoomControl";
import ToggleBasemapControl from "../../map/ToggleBasemapControl";
import { DEFAULT_MAP_CENTER } from "../../../pages/publicMap/constants";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapContainer = styled.div`
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 100%;
`;

const DUMMY_BASEMAP_LAYERS = [
  { url: "streets-v11", icon: "commute" },
  { url: "outdoors-v11", icon: "park" },
  { url: "satellite-streets-v11", icon: "satellite_alt" },
];

const locationsLayer = {
  id: "locations",
  type: "circle",
  source: "locations",
  paint: {
    "circle-stroke-width": 2,
    "circle-stroke-color": "#fff",
    "circle-radius": 7,
    "circle-color": "#1F78B4",
  },
};

const locationsLabelsLayer = {
  id: "locations-labels",
  type: "symbol",
  source: "locations",
  minzoom: 9,
  layout: {
    "text-field": [
      "format",
      ["get", "description"],
      {}, // Use default formatting
      "\n",
      {},
      ["get", "name"],
      {
        "text-font": ["literal", ["DIN Offc Pro Italic"]],
        "font-scale": 0.85,
      },
    ],
    "text-size": 12,
    "text-offset": [0, -2.75],
    "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
    visibility: "visible",
  },
  paint: {
    "text-color": "rgb(49,49,49)",
    "text-halo-color": "rgba(255,255,255,1)",
    "text-halo-width": 3,
  },
};

const selectedLocationsLayer = {
  id: "selected-locations",
  type: "circle",
  source: "selected-locations",
  paint: {
    "circle-stroke-width": 2,
    "circle-stroke-color": "black",
    "circle-radius": 7,
    "circle-color": "gold",
  },
};

const selectedLocationsLabelsLayer = {
  ...locationsLabelsLayer,
  id: "selected-locations-labels",
  source: "selected-locations",
};

const basinsFill = {
  id: "basins-fill",
  name: "Basins",
  type: "fill",
  source: "basins",
  "source-layer": "dw-huc12-basins-merged-9a255p",
  paint: {
    "fill-color": "#cb590a",
    "fill-opacity": 0,
  },
};

const basinsLine = {
  id: "basins-line",
  name: "Basins",
  type: "line",
  source: "basins",
  "source-layer": "dw-huc12-basins-merged-9a255p",
  paint: {
    "line-color": "#cb590a",
    "line-width": 3,
  },
};

function buildLocationsGeoJson(locations = []) {
  return {
    type: "FeatureCollection",
    features: locations.map((location) => {
      return {
        id: location.location_index,
        type: "Feature",
        properties: {
          name: location.location_title,
          id: location.location_index,
          index: location.location_index,
        },
        geometry: {
          type: "Point",
          coordinates: [location.loc_long, location.loc_lat],
        },
      };
    }),
  };
}

const LocationsFilterMap = ({ locations = [], selectedLocations = [] }) => {
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const mapContainer = useRef(null); // create a reference to the map container

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: DEFAULT_MAP_CENTER,
      zoom: 7,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new ResetZoomControl(), "top-left");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && typeof map != "undefined") {
      const locationsSource = map.getSource("locations");
      const selectedLocationsSource = map.getSource("selected-locations");
      const basinsSource = map.getSource("basins");

      if (!basinsSource) {
        map.addSource("basins", {
          type: "vector",
          url: "mapbox://dwwsstrat.6iwv59ij",
        });
        map.addLayer(basinsLine);
        map.addLayer(basinsFill);

        map.on("click", "basins-fill", (e) => {
          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.basin)[0];

          const description = feature.properties.basin;

          const basinPopup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);

          map.on("closeAllPopups", () => {
            basinPopup.remove();
          });
        });
      }

      if (!locationsSource) {
        map.addSource("locations", {
          type: "geojson",
          data: buildLocationsGeoJson(locations),
        });
        map.addLayer(locationsLayer);
        map.addLayer(locationsLabelsLayer);

        map.on("click", "locations", (e) => {
          map.fire("closeAllPopups");

          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.name)[0];
          const description = feature?.properties?.name;

          const popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);

          map.on("closeAllPopups", () => {
            popup.remove();
          });

          // Change the cursor to a pointer when the mouse is over the places layer.
          map.on("mouseenter", "locations", () => {
            map.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          map.on("mouseleave", "locations", () => {
            map.getCanvas().style.cursor = "";
          });
        });
      } else {
        locationsSource.setData(buildLocationsGeoJson(locations));
      }

      if (!selectedLocationsSource) {
        map.addSource("selected-locations", {
          type: "geojson",
          data: buildLocationsGeoJson(selectedLocations),
        });
        map.addLayer(selectedLocationsLayer);
        map.addLayer(selectedLocationsLabelsLayer);
      } else {
        // maintaining a separate source for selected locations so that we can
        // ensure that the selected locations are always on top of all other locations
        // Mapbox allows us to set the z-index of a layer using the circle-sort-key property
        // but layout properties do not support data-driven styling using feature state
        // so we are maintaining two separate sources for locations and selected locations
        // is the easiest way to ensure that selected locations are always on top
        map
          .getSource("selected-locations")
          .setData(buildLocationsGeoJson(selectedLocations));
      }
    }
  }, [mapIsLoaded, map, locations, selectedLocations]); //eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("selected-locations")) {
    }
  }, [selectedLocations]); //eslint-disable-line

  return <MapContainer ref={mapContainer} />;
};

export default LocationsFilterMap;
