import React, { useRef } from "react";

import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider as MuiDivider,
  FormControl,
  FormLabel,
  Grid as MuiGrid,
  TextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Button,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { useTemperatureVsFlow } from "./useTemperatureVsFlow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import TimeseriesCompareLineChart from "../../../components/graphs/TimeseriesCompareLineChart";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import Loader from "../../../components/Loader";

const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
  padding: 10px;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

function TemperatureVsFlow() {
  const saveRef = useRef(null);
  const { data, filterValues, handleFilterChange } = useTemperatureVsFlow();

  return (
    <React.Fragment>
      <Helmet title="Stream Temp and Air Temp vs. Flow" />
      <Typography variant="h3" gutterBottom display="inline">
        Stream Temp and Air Temp vs. Flow
      </Typography>
      <Divider my={6} />

      <Typography variant="h6" gutterBottom>
        Getting Started
      </Typography>

      <Typography variant="body1" gutterBottom>
        This tools provides the ability to explore the relationship between
        stream temperature, air temperature, and stream flows.
      </Typography>

      <Accordion
        defaultExpanded
        style={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "5px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="time-series"
          id="time-series"
        >
          <Typography variant="h4" ml={2}>
            Filter Controls
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FiltersContainer>
            <Grid container spacing={3}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginLeft: "6px" }}
              >
                Time Period Filters
              </Typography>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  style={{ marginRight: "10px" }}
                >
                  <FormLabel component="legend">Start Date</FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={filterValues.startDate}
                      onChange={(newValue) =>
                        handleFilterChange("startDate", newValue)
                      }
                      variant="inline"
                      format="MM/dd/yyyy"
                      autoOk
                      inputVariant="outlined"
                      size="small"
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">End Date</FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={filterValues.endDate}
                      onChange={(newValue) =>
                        handleFilterChange("endDate", newValue)
                      }
                      variant="inline"
                      format="MM/dd/yyyy"
                      autoOk
                      inputVariant="outlined"
                      size="small"
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <Divider mt={6} mb={2} />
              </Grid>

              <Typography variant="h6" ml={2} gutterBottom>
                Stream Temperature Filters
              </Typography>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ marginBottom: "2px" }}
                  size="small"
                  id="streamTempStatistics"
                  name="streamTempStatistics"
                  options={data.statistics}
                  getOptionLabel={(option) => option.label ?? ""}
                  multiple
                  limitTags={3}
                  disableCloseOnSelect
                  filterSelectedOptions
                  onChange={(e, value) => {
                    handleFilterChange("streamTempStatistics", value);
                  }}
                  value={filterValues.streamTempStatistics}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        color="primary"
                        variant="outlined"
                        label="Statistics"
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {data?.streamTempLocations?.isFetching ? (
                  <Loader />
                ) : (
                  <Autocomplete
                    size="small"
                    id="streamTempLocations"
                    name="streamTempLocations"
                    options={data?.streamTempLocations?.data}
                    getOptionLabel={(option) =>
                      option.location_id
                        ? `${option.location_id} - ${option.location_name}`
                        : ""
                    }
                    limitTags={3}
                    filterSelectedOptions
                    onChange={(e, value) => {
                      handleFilterChange("streamTempLocation", value);
                    }}
                    value={filterValues.streamTempLocation}
                    freeSolo
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          color="primary"
                          variant="outlined"
                          label="Location"
                        />
                      );
                    }}
                  />
                )}

                <Divider mt={6} mb={2} />
              </Grid>

              <Typography variant="h6" ml={2} gutterBottom>
                Air Temperature Filters
              </Typography>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ marginBottom: "2px" }}
                  size="small"
                  id="airTempStatistics"
                  name="airTempStatistics"
                  options={data.statistics}
                  getOptionLabel={(option) => option.label ?? ""}
                  multiple
                  limitTags={3}
                  disableCloseOnSelect
                  filterSelectedOptions
                  onChange={(e, value) => {
                    handleFilterChange("airTempStatistics", value);
                  }}
                  value={filterValues.airTempStatistics}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        color="primary"
                        variant="outlined"
                        label="Statistics"
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {data?.airTempLocations?.isFetching ? (
                  <Loader />
                ) : (
                  <Autocomplete
                    size="small"
                    id="airTempLocations"
                    name="airTempLocations"
                    options={data?.airTempLocations?.data}
                    getOptionLabel={(option) =>
                      option.location_id
                        ? `${option.location_id} - ${option.location_name}`
                        : ""
                    }
                    limitTags={3}
                    filterSelectedOptions
                    onChange={(e, value) => {
                      handleFilterChange("airTempLocation", value);
                    }}
                    value={filterValues.airTempLocation}
                    freeSolo
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          color="primary"
                          variant="outlined"
                          label="Location"
                        />
                      );
                    }}
                  />
                )}
                <Divider mt={6} mb={2} />
              </Grid>

              <Typography variant="h6" ml={2} gutterBottom>
                Stream Flow Filters
              </Typography>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ marginBottom: "2px" }}
                  size="small"
                  id="streamFlowStatistics"
                  name="streamFlowStatistics"
                  options={data.statistics}
                  getOptionLabel={(option) => option.label ?? ""}
                  multiple
                  limitTags={3}
                  disableCloseOnSelect
                  filterSelectedOptions
                  onChange={(e, value) => {
                    handleFilterChange("streamFlowStatistics", value);
                  }}
                  value={filterValues.streamFlowStatistics}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        color="primary"
                        variant="outlined"
                        label="Statistics"
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {data?.streamFlowLocations?.isFetching ? (
                  <Loader />
                ) : (
                  <Autocomplete
                    size="small"
                    id="streamFlowLocations"
                    name="streamFlowLocations"
                    options={data?.streamFlowLocations?.data}
                    getOptionLabel={(option) =>
                      option.location_id
                        ? `${option.location_id} - ${option.location_name}`
                        : ""
                    }
                    limitTags={3}
                    filterSelectedOptions
                    onChange={(e, value) => {
                      handleFilterChange("streamFlowLocation", value);
                    }}
                    value={filterValues.streamFlowLocation}
                    freeSolo
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          color="primary"
                          variant="outlined"
                          label="Location"
                        />
                      );
                    }}
                  />
                )}
                <Divider mt={6} mb={4} />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => data.graph.refetch()}
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                style={{ marginLeft: "10px" }}
                disabled={
                  //The start date or end date is not provided.
                  // No complete pair is selected.
                  // Any pair is partially selected.
                  // The graph is fetching data.
                  !filterValues.startDate ||
                  !filterValues.endDate ||
                  !(
                    (filterValues.streamTempStatistics.length &&
                      filterValues.streamTempLocation) ||
                    (filterValues.airTempStatistics.length &&
                      filterValues.airTempLocation) ||
                    (filterValues.streamFlowStatistics.length &&
                      filterValues.streamFlowLocation)
                  ) ||
                  (filterValues.streamTempStatistics.length > 0 &&
                    !filterValues.streamTempLocation) ||
                  (filterValues.streamTempLocation &&
                    filterValues.streamTempStatistics.length === 0) ||
                  (filterValues.airTempStatistics.length > 0 &&
                    !filterValues.airTempLocation) ||
                  (filterValues.airTempLocation &&
                    filterValues.airTempStatistics.length === 0) ||
                  (filterValues.streamFlowStatistics.length > 0 &&
                    !filterValues.streamFlowLocation) ||
                  (filterValues.streamFlowLocation &&
                    filterValues.streamFlowStatistics.length === 0) ||
                  data.graph.isFetching
                }
              >
                Submit
              </Button>
            </Grid>
          </FiltersContainer>
        </AccordionDetails>
      </Accordion>

      {(data.graph.isFetching || data.graph.data) && (
        <Paper
          p={3}
          style={{
            margin: "16px 0",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "5px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-between",
            }}
          >
            <Typography
              variant="h4"
              ml={2}
              mt="8px"
              mb="20px"
              style={{ width: "100%" }}
            >
              Stream Temp and Air Temp vs. Flow
            </Typography>
            {!data.graph.isFetching && data.graph.data && (
              <SaveGraphButton
                style={{ marginBottom: "10px" }}
                ref={saveRef}
                title="Stream Temp and Air Temp vs. Flow"
                disabled={data.graph.isFetching || !data.graph.data}
              />
            )}
          </Box>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            {data.graph.isFetching ? (
              <Loader />
            ) : (
              <TimeseriesContainer>
                <TableWrapper>
                  <TimeseriesCompareLineChart
                    data={data.graph.data}
                    error={data.graph.error}
                    isLoading={data.graph.isFetching}
                    filterValues={filterValues}
                    yLLabel={data.graph.data?.yLLabel}
                    yRLabel={data.graph.data?.yRLabel}
                    xLabelUnit="week"
                    ref={saveRef}
                    tooltipFormat="MM-DD-YYYY"
                    footerLabel="Hours Pumped"
                  />
                </TableWrapper>
              </TimeseriesContainer>
            )}
          </Box>
        </Paper>
      )}
    </React.Fragment>
  );
}

export default TemperatureVsFlow;
