const NORTH_FORK_METALS = {
  startDate: new Date(new Date().getFullYear() - 5, 9, 1),
  endDate: new Date(),
  locationsLeft: [7, 1054, 35, 36, 52, 135, 56],
  locationsRight: [100581, 100591, 100645],
  parameterLeft: [24],
  parameterRight: [2346],
  statisticLeft: "avg",
  statisticRight: "avg",
  timeInterval: "daily",
};

const RALSTON_CREEK_URANIUM = {
  startDate: new Date(new Date().getFullYear() - 5, 9, 1),
  endDate: new Date(),
  locationsLeft: [1769, 1768, 40],
  locationsRight: [100632],
  parameterLeft: [56],
  parameterRight: [2346],
  statisticLeft: "avg",
  statisticRight: "avg",
  timeInterval: "daily",
};

const HOURLY_STREAM_TEMP = {
  startDate: new Date("2022-07-01"),
  endDate: new Date("2022-07-08"),
  locationsLeft: [
    3666, 3663, 3675, 3671, 3665, 3664, 3673, 3662, 3669, 3672, 3670,
  ],
  locationsRight: [],
  parameterLeft: [2344],
  parameterRight: [],
  statisticLeft: "avg",
  statisticRight: "avg",
  timeInterval: "hourly",
};

export const SAVED_ANALYSES_FILTER_VALUES = {
  nfm: NORTH_FORK_METALS,
  rcu: RALSTON_CREEK_URANIUM,
  hst: HOURLY_STREAM_TEMP,
};

export const STATISTIC_OPTIONS = [
  { value: "min", label: "Min" },
  { value: "max", label: "Max" },
  { value: "avg", label: "Avg" },
];

export const DAILY_STATISTIC_OPTIONS = [
  { value: "min", label: "Daily Min" },
  { value: "max", label: "Daily Max" },
  { value: "avg", label: "Daily Avg" },
];

export const TIME_INTERAVAL_OPTIONS = [
  { value: "hourly", label: "Hourly" },
  { value: "daily", label: "Daily" },
];

export const PARAMETERS_WITH_MIN_MAX = [
  2344, // stream temp
  2296, // air temp
  2346, // stream flow
];

export const PARAMETERS_WITH_HOURLY_INTERVAL = [
  2344, // stream temp
  2346, // stream flow
];
