import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";
import { dateFormatter, formatBooleanTrueFalse } from "../../../utils";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const Popup = ({
  features,
  layers,
  handleGraphModeFromPoint,
  height = "200px",
  width = "380px",
  size = "medium",
}) => {
  function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      if (
        existingFeatureKeys[el[comparatorProperty1] + el[comparatorProperty2]]
      ) {
        return false;
      } else {
        existingFeatureKeys[
          el[comparatorProperty1] + el[comparatorProperty2]
        ] = true;
        return true;
      }
    });
  }

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = layers?.find(
      (layer) => layer?.id === feature?.layer?.id
    )?.lreProperties?.popup?.excludeFields;
    setExcludeFields(excludedFields || []);
  }, [feature, layers]);

  let popupData;
  if (feature?.layer?.id === "denver-water-locations-circle") {
    popupData = [
      ...(feature?.properties?.system_page_url
        ? [
            [
              "Source Graph",
              <Link
                target="_blank"
                rel="noreferrer noopener"
                href={feature?.properties?.system_page_url}
              >
                View Data
              </Link>,
            ],
          ]
        : []),
      ...(feature?.properties?.has_wq_data
        ? [
            [
              "Graph Mode",
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() =>
                  handleGraphModeFromPoint(feature?.properties?.ndx)
                }
              >
                View Data
              </Button>,
            ],
          ]
        : []),
      [
        "Period of Record",
        `${new Date(
          feature?.properties.src_por_start
        ).getFullYear()} - ${new Date(
          feature?.properties.src_por_end
        ).getFullYear()}`,
      ],
      [
        "Organizations",
        feature?.properties.organizations
          .replace(/["{}[\]]/g, "")
          .replaceAll(",", ", "),
      ],
      ["Record Count", feature?.properties.src_resultcount],
      ["HUC 12", feature?.properties.huc12],
      ["Basin", feature?.properties.basin],
      [
        "Sources",
        feature?.properties.sources
          .replace(/["{}[\]]/g, "")
          .replaceAll(",", ", "),
      ],
      ["Latitude", feature?.properties.x_lat],
      ["Longitude", feature?.properties.y_lon],
      ...(feature?.properties?.most_recent_report !== null
        ? [
            [
              "Most Recent Report",
              dateFormatter(
                feature?.properties.most_recent_report,
                "M/D/YYYY h:mm A"
              ),
            ],
          ]
        : []),
      ...(feature?.properties?.most_recent_value !== null
        ? [["Most Recent Value", feature?.properties.most_recent_value]]
        : []),
      ...(feature?.properties?.most_recent_value_units !== null
        ? [
            [
              "Most Recent Value Units",
              feature?.properties.most_recent_value_units,
            ],
          ]
        : []),
    ];
  } else {
    popupData = excludeFields
      ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
          //MJB also removing entry if the value is an empty string, null, or undefined
          if (
            !excludeFields.includes(key) &&
            value !== "" &&
            !isNullOrUndef(value)
          ) {
            acc.push([key, value]);
          }
          return acc;
        }, [])
      : Object.entries(feature?.properties);
  }

  const [titleField, setTitleField] = useState("");

  useEffect(() => {
    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;
    setTitleField(
      (title &&
        feature?.properties[title] &&
        `${feature?.properties[title]} (${titleize(
          feature?.layer?.source
        )})`) ||
        titleize(feature?.layer?.source)
    );
  }, [feature, layers]);

  if (!popupData) return null;
  return (
    <>
      <h2 style={{ marginBottom: 0, width: width }}>
        {feature?.layer?.id === "denver-water-locations-circle"
          ? feature?.properties.location_name
          : titleField}
      </h2>
      <h3 style={{ marginTop: 0, width: width }}>
        {feature?.layer?.id === "denver-water-locations-circle" &&
          feature?.properties.location_id}
      </h3>
      <PopupWrap height={height} width={width}>
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value]) => {
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>{titleize(key)}</strong>
                  </PopupCell>
                  <PopupCell>{formatBooleanTrueFalse(value)}</PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={size}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default Popup;
