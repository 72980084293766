import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  SearchOutlined,
  RadioButtonUnchecked as UnselectedIcon,
  CheckCircle as SelectedIcon,
} from "@material-ui/icons";
import { FixedSizeList } from "react-window";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";

const ListOptionContainer = styled("article")`
  background-color: ${(props) => (props.selected ? "#edf6ff" : "#fff")};
  border: 1px solid #ddd;
  border-color: ${(props) => (props.selected ? "#1976d2" : " #ddd")};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px;

  &:hover {
    border-color: #1976d2;
  }

  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const ListOption = ({
  displayField,
  subtitleFields,
  onSelect,
  option,
  selected,
}) => {
  return (
    <ListOptionContainer selected={selected} onClick={() => onSelect(option)}>
      {selected ? (
        <SelectedIcon style={{ color: "#1976d2" }} />
      ) : (
        <UnselectedIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
      )}
      <Box
        display="flex"
        flexDirection="column"
        ml="16px"
        width={"100%"}
        minWidth={"0"}
      >
        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {option[displayField]}
        </Typography>
        <Box display={"flex"} flexDirection={"row"}>
          {subtitleFields.map((subtitleField) => {
            return (
              <Box
                key={`${option[displayField]} - ${subtitleField}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                minWidth={"0"}
                width={170}
              >
                <Typography
                  variant="body2"
                  color={"textSecondary"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: "8px",
                  }}
                  title={option[subtitleField]}
                >
                  {option[subtitleField]}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </ListOptionContainer>
  );
};

const ListSelect = ({
  isMultiSelect = true,
  options = [],
  displayField,
  subtitleFields,
  valueField,
  searchValue = "",
  value = [],
  onSearch,
  onSelect,
  onSelectAll,
  onSelectNone,
}) => {
  return (
    <Box width={"100%"}>
      <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
        <OutlinedInput
          id="outlined-adornment-search"
          value={searchValue}
          onChange={onSearch}
          startAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="start">
                <SearchOutlined />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="outlined-search-helper-text"
          placeholder="Search"
          inputProps={{
            "aria-label": "search",
          }}
          size={"small"}
        />
      </FormControl>
      {isMultiSelect && (
        <ButtonGroup>
          <Button
            color={"primary"}
            size={"small"}
            variant={"outlined"}
            onClick={onSelectAll}
          >
            + All
          </Button>
          <Button
            color={"primary"}
            size={"small"}
            variant={"outlined"}
            onClick={onSelectNone}
          >
            - None
          </Button>
        </ButtonGroup>
      )}
      <Box mt={4} borderBottom={"1px solid rgba(0,0,0,0.12)"}>
        {options?.length > 0 && (
          <FixedSizeList
            height={700}
            width={"100%"}
            itemSize={68}
            itemCount={options.length}
          >
            {({ index, style }) => {
              return (
                <div style={style} key={options[index][valueField]}>
                  <ListOption
                    displayField={displayField}
                    subtitleFields={subtitleFields}
                    option={options[index]}
                    onSelect={onSelect}
                    selected={value.some(
                      (item) => item[valueField] === options[index][valueField]
                    )}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        )}
        {options?.length === 0 && (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textAlign: "center",
              paddingTop: "8px",
              paddingBottom: "24px",
            }}
          >
            No results found
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ListSelect;
