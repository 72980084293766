import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const CheckboxGroup = ({
  label,
  displayField,
  name,
  options,
  onChange,
  onSelectAll,
  onSelectNone,
  value,
  valueField = [],
}) => {
  const handleSelectAll = () => {
    onSelectAll(name);
  };

  const handleSelectNone = () => {
    onSelectNone(name);
  };

  if (!options || !options.length) {
    return null;
  }

  return (
    <FormControl component="fieldset">
      <Typography variant="subtitle1" component="legend">
        {label}
      </Typography>
      <ButtonGroup>
        <Button
          color={"primary"}
          size={"small"}
          variant={"outlined"}
          onClick={handleSelectAll}
        >
          + All
        </Button>
        <Button
          color={"primary"}
          size={"small"}
          variant={"outlined"}
          onClick={handleSelectNone}
        >
          - None
        </Button>
      </ButtonGroup>
      <FormGroup>
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color={"primary"}
                  checked={value.includes(option[valueField])}
                  onChange={onChange}
                  name={`${name}--${option[valueField]}`}
                  style={{ padding: "4px 12px" }}
                />
              }
              label={option[displayField]}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
