import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { Box, lighten, Typography } from "@material-ui/core";
import { SCHEME_10_TABLEAU_COLORS } from "../../utils";

const SiteStatisticsBoxPlot = ({ data, error, legendItems }) => {
  if (!data) return null;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Box width={"100%"} height={"100%"}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {legendItems?.map((value, index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mr={4} mb={2}>
              <Box
                height="12px"
                width="24px"
                border={`2px solid ${SCHEME_10_TABLEAU_COLORS[index % 10]}`}
                borderColor={SCHEME_10_TABLEAU_COLORS[index % 10]}
                bgcolor={lighten(SCHEME_10_TABLEAU_COLORS[index % 10], 0.5)}
                mr={1}
              />
              <Typography variant="body2">{value}</Typography>
            </Box>
          );
        })}
      </Box>
      <ResponsiveBoxPlot
        data={data}
        margin={{ top: 60, right: 16, bottom: 60, left: 60 }}
        subGroupBy="subGroup"
        padding={0.12}
        enableGridX={true}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: 36,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Year",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend:
            `${data?.[0]?.parameter} (${data?.[0]?.units})` || "Result Value",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        colors={{ scheme: "category10" }}
        borderRadius={2}
        borderWidth={2}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        medianWidth={2}
        medianColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        whiskerEndSize={0.6}
        whiskerColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        motionConfig="stiff"
        quantiles={[0, 0.25, 0.5, 0.75, 1]}
      />
    </Box>
  );
};

export default SiteStatisticsBoxPlot;
