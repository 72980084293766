import React from "react";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@material-ui/core";

const Container = styled.pre`
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  bottom: 70px;
  right: 49px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 2;
  //display: none;
`;

const MinimalTable = () => {
  const rows = [
    {
      source: "CO Data Sharing Network",
      updateFrequency: "Daily data uploads",
    },
    {
      source: "Colorado DWR/Colorado DWR (USGS)",
      updateFrequency:
        "Daily: average, minimum, and maximum values are calculated daily from 15-minute data imports",
    },
    {
      source: "Denver Water LIMS",
      updateFrequency:
        "Refreshed and uploaded at the end of each month, data can have up to a 30-day delay for analysis and review post collection",
    },
    { source: "National WQ Portal", updateFrequency: "Daily data uploads" },
  ];

  const cellStyle = {
    wordBreak: "break-word",
    fontSize: "0.8rem",
    color: "white",
  };

  const tableContainerStyle = {
    backgroundColor: "transparent",
    maxWidth: 400,
    margin: "auto",
    textWrap: "wrap",
  };

  return (
    <TableContainer component={Paper} style={tableContainerStyle}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ ...cellStyle, fontWeight: 700 }}>
              Source
            </TableCell>
            <TableCell style={{ ...cellStyle, fontWeight: 700 }}>
              Update Frequency
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" style={cellStyle}>
                {row.source}
              </TableCell>
              <TableCell style={cellStyle}>{row.updateFrequency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// For routes that can only be accessed by admin users
const UpdateFrequencyPopup = ({ onHide }) => {
  return (
    <>
      <Container>
        <MinimalTable />
        <Box display="flex" justifyContent="end">
          <Button
            style={{ marginLeft: "10px" }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={onHide}
          >
            Hide
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default UpdateFrequencyPopup;
