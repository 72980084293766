import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import { useQuery } from "react-query";
import ResetZoomControl from "./ResetZoomControl";
import ToggleBasemapControl from "./ToggleBasemapControl";
import debounce from "lodash.debounce";
import { DEFAULT_LINE_COLORS } from "../../utils";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import createTheme from "../../theme";
import Popup from "../../pages/publicMap/popup";
import { useSelector } from "react-redux";
import axios from "axios";
import { DEFAULT_MAP_CENTER } from "../../pages/publicMap/constants";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WaterQualityReportCardMap = () => {
  const theme = useSelector((state) => state.themeReducer);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const { data, isLoading, error } = useQuery(
    ["public-map/wells"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/wells`
        );
        return data.filter(
          (location) => location.location_geometry && location.abasin_point
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainer = useRef(null); // create a reference to the map container

  const DUMMY_BASEMAP_LAYERS = [
    { url: "streets-v11", icon: "commute" },
    { url: "outdoors-v11", icon: "park" },
    { url: "satellite-streets-v11", icon: "satellite_alt" },
  ];

  const locationsLayer = {
    id: "locations",
    type: "circle",
    source: "locations",
    paint: {
      "circle-stroke-width": 1,
      "circle-stroke-color": "black",
      "circle-radius": 7,
      "circle-color": [
        "case",
        ["==", ["get", "dw_collection_area"], "North"],
        "orange",
        ["==", ["get", "dw_collection_area"], "South"],
        "blue",
        DEFAULT_LINE_COLORS.black,
      ],
    },
    lreProperties: {
      popup: {
        titleField: "abasin_name",
        // excludeFields: ["index", "description"],
      },
    },
  };

  const locationsSymbolLayer = {
    id: "denver-water-locations-symbol",
    type: "symbol",
    source: "locations",
    layout: {
      "text-field": [
        "format",
        ["get", "description"],
        {}, // Use default formatting
        "\n",
        {},
        ["get", "id"],
        {
          "text-font": ["literal", ["DIN Offc Pro Italic"]],
          "font-scale": 0.85,
        },
      ],
      "text-size": 12,
      "text-offset": [0, -3.75],
      "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
    },
    paint: {
      "text-color": "rgb(49,49,49)",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 3,
    },
  };

  const waterQualityAssessmentBoundaryFill = {
    id: "water-quality-assessment-boundary-fill",
    name: "Water Quality Assessment Boundaries",
    type: "fill",
    source: "water-quality-assessment-boundary",
    "source-layer": "wq_assessment_basins-3do1ss",
    paint: {
      "fill-color": "#ffa8f8",
      "fill-opacity": 0,
    },
    lreProperties: {
      layerGroup: "water-quality-assessment-boundary",
    },
  };

  const waterQualityAssessmentBoundaryLine = {
    id: "water-quality-assessment-boundary-line",
    name: "Water Quality Assessment Boundaries",
    type: "line",
    source: "water-quality-assessment-boundary",
    "source-layer": "wq_assessment_basins-3do1ss",
    paint: {
      "line-color": "#ffa8f8",
      "line-width": 3,
    },
    lreProperties: {
      layerGroup: "water-quality-assessment-boundary",
    },
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: DEFAULT_MAP_CENTER,
      zoom: 7.2,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl());
    // Add locate control to the map.
    map.addControl(new ResetZoomControl(), "top-left");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      if (!map.getSource("locations")) {
        map.addSource("water-quality-assessment-boundary", {
          type: "vector",
          url: "mapbox://dwwsstrat.0wag4vwt",
        });

        map.addLayer(waterQualityAssessmentBoundaryFill);
        map.addLayer(waterQualityAssessmentBoundaryLine);

        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                id: location.location_index,
                type: "Feature",
                properties: {
                  description: location.location_name,
                  id: location.location_id,
                  index: location.location_index,
                  locType: location.loc_type,
                  organization: location.organization,
                  source: location.source,
                  huc12: location.huc12,
                  dw_collection_area: location.dw_collection_area,
                  abasin_name: location.abasin_name,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });
        // Add a layer showing the places.
        map.addLayer(locationsLayer);
        map.addLayer(locationsSymbolLayer);

        map.on("click", "water-quality-assessment-boundary-fill", (e) => {
          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.BasinName)[0];

          const description = feature.properties.BasinName;

          const basinPopup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);

          map.on("closeAllPopups", () => {
            basinPopup.remove();
          });
        });

        map.on("click", "locations", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter(
            (feature) => feature.source === "locations"
          );
          const coordinates = [e.lngLat.lng, e.lngLat.lat];

          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={myFeatures}
                    width="252px"
                    height="141px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "locations", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "locations", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [isLoading, mapIsLoaded, map, data]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;

  return (
    <Root>
      <MapContainer ref={mapContainer} />
    </Root>
  );
};

export default WaterQualityReportCardMap;
