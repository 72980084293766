import { useEffect, useState } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../constants";

const useLayerStyles = ({ onLayerStyleChange }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  const buildScale = (values) => {
    const scale = scaleOrdinal(schemePaired);
    return values.reduce((acc, val) => {
      acc.push([val]);
      acc.push(scale(val));
      return acc;
    }, []);
  };

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        locationTypes: {
          ...prevState.locationTypes,
          paint: {
            "circle-color": [
              "match",
              ["get", "loc_type"],
              ...buildScale(data.locationTypes.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
        organizations: {
          ...prevState.organizations,
          paint: {
            "circle-color": [
              "match",
              ["get", "org_fav"],
              ...buildScale(data.organizations.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": [
              "case",
              [">", ["length", ["get", "organizations"]], 1],
              5,
              2,
            ],
            "circle-stroke-color": [
              "case",
              [">", ["length", ["get", "organizations"]], 1],
              "white",
              "black",
            ],
          },
        },
        basins: {
          ...prevState.basins,
          paint: {
            "circle-color": [
              "match",
              ["get", "basin"],
              ...buildScale(data.basins.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
        sources: {
          ...prevState.sources,
          // paint: {
          //   "circle-color": [
          //     "case",
          //     ...buildScaleArray(data.sources.map((item) => item.display)),
          //     "#000000",
          //   ],
          // },
          paint: {
            "circle-color": [
              "match",
              ["get", "source_fav"],
              ...buildScale(data.sources.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": [
              "case",
              [">", ["length", ["get", "sources"]], 1],
              5,
              2,
            ],
            "circle-stroke-color": [
              "case",
              [">", ["length", ["get", "sources"]], 1],
              "white",
              "black",
            ],
          },
        },
      }));
    }
  }, [data]);

  const [activeStyle, setActiveStyle] = useState(styleValues.default);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
