import axios from "axios";
import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { groupByValueArray } from "../../../utils";
import { lighten } from "@material-ui/core";
import moment from "moment";
import { COLOR_PALETTES } from "../../../utils";

const PRETTY_STATISTICS_LOOKUP = {
  avg: "Daily Averages",
  min: "Daily Minimums",
  max: "Daily Maximums",
};

const BAR_CHART_RESULT_FIELDS_BY_STATISTIC = {
  avg: "avg_value",
  min: "min_value",
  max: "max_value",
};

const BOX_PLOT_RESULT_FIELDS_BY_STATISTIC = {
  avg: "avg_value",
  min: "min_value",
  max: "max_value",
};

export const useSiteComparisons = () => {
  const [isSubmitDirty, setIsSubmitDirty] = useState(false);

  const [filterValues, setFilterValues] = useState({
    //two years ago from this years 10/1
    startDate: new Date(new Date().getFullYear() - 2, 9, 1),
    endDate: new Date(),
    locations: [],
    parameter: [],
    statistic: "avg",
  });

  //UseQuery calls using the useDataQuery custom hook
  const {
    data: boxPlotData,
    error: boxPlotError,
    isLoading: isBoxPlotLoading,
    refetch: refetchBoxPlot,
  } = useQuery(
    ["box-plot-data"],
    async () => {
      try {
        const parameter = filterValues.parameter?.[0] || -1;
        const locations = filterValues.locations?.join(",") || -1;
        const startDate = moment(filterValues.startDate).format("YYYY-MM-DD");
        const endDate = moment(filterValues.endDate).format("YYYY-MM-DD");

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/site-comparisons/box-plot/${locations}/${parameter}/${startDate}/${endDate}`
        );

        if (data?.length > 0) {
          const resultField =
            BOX_PLOT_RESULT_FIELDS_BY_STATISTIC[filterValues.statistic];

          return data?.map((d) => {
            return {
              group: new Date(d.collect_date).getFullYear(),
              subGroup: !!d.location_id
                ? `${d.location_id} - ${d.location_name}`
                : d.location_name,
              value: d[resultField],
              units: d.units,
              parameter: d.parameter,
            };
          });
        } else {
          return [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: barChartData,
    error: barChartError,
    isLoading: isBarChartLoading,
    refetch: refetchBarChart,
  } = useQuery(
    ["bar-chart-data"],
    async () => {
      try {
        const parameter = filterValues.parameter?.[0] || -1;
        const locations = filterValues.locations?.join(",") || -1;
        const startDate = moment(filterValues.startDate).format("YYYY-MM-DD");
        const endDate = moment(filterValues.endDate).format("YYYY-MM-DD");

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/site-comparisons/bar-chart/${locations}/${parameter}/${startDate}/${endDate}`
        );

        return groupByValueArray(data, "location_title");
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [parsedBarChartData, setParsedBarChartData] = useState([]);
  const [barChartUi, setBarChartUi] = useState({
    title: "Monthly Averages by Location",
  });

  useEffect(() => {
    if (barChartData?.length > 0) {
      const resultField =
        BAR_CHART_RESULT_FIELDS_BY_STATISTIC[filterValues.statistic];

      const parsedData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: barChartData?.map((d, i) => {
          return {
            label: d[0].location_title,
            data: d.map((item) => {
              return {
                x: item.month_name,
                y: item[resultField],
              };
            }),
            fill: false,
            borderColor: COLOR_PALETTES.SCHEME_10_TABLEAU_COLORS[i % 10],
            backgroundColor: lighten(
              COLOR_PALETTES.SCHEME_10_TABLEAU_COLORS[i % 10],
              0.5
            ),
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 0,
            units: d[0].units,
          };
        }),
      };

      const prettyStatistic = PRETTY_STATISTICS_LOOKUP[filterValues.statistic];
      const parameterName = barChartData?.[0]?.[0]?.parameter_name;
      const units = barChartData?.[0]?.[0]?.units;

      const title = `Monthly Averages by Location - ${prettyStatistic}`;
      const subtitle = `${parameterName} (${units})`;

      setBarChartUi({
        yLLabel: `${parameterName} (${units})` || "Results",
        title,
        subtitle,
      });
      setParsedBarChartData(parsedData);
    } else {
      setBarChartUi({
        yLLabel: "Results",
        title: "Monthly Averages by Location - No Data Available",
        subtitle: null,
      });
      setParsedBarChartData([]);
    }
  }, [barChartData]); //eslint-disable-line react-hooks/exhaustive-deps

  const barChartTableColumns = [
    {
      title: "Month",
      field: "month_name",
    },
    { title: "Location Name", field: "location_title" },
    { title: "Parameter", field: "parameter_name" },
    { title: "Units", field: "units" },
    {
      title: "Min Value",
      field: "min_value",
      render: (rowData) => rowData?.min_value?.toFixed(2),
    },
    {
      title: "Avg Value",
      field: "avg_value",
      render: (rowData) => rowData?.avg_value?.toFixed(2),
    },
    {
      title: "Max Value",
      field: "max_value",
      render: (rowData) => rowData?.max_value?.toFixed(2),
    },
  ];

  //---------------------

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = () => {
    setIsSubmitDirty(false);
    refetchBoxPlot();
    refetchBarChart();
    setIsSubmitDirty(true);
  };

  return {
    data: {
      boxPlot: {
        data: boxPlotData,
        ui: {
          legendItems: [...new Set(boxPlotData?.map((d) => d.subGroup) || [])],
        },
        isLoading: isBoxPlotLoading,
        error: boxPlotError,
        refetch: refetchBoxPlot,
      },
      barChart: {
        data: parsedBarChartData,
        tableData: barChartData?.flat() || [],
        ui: barChartUi,
        isLoading: isBarChartLoading,
        error: barChartError,
        refetch: refetchBarChart,
        tableColumns: barChartTableColumns,
      },
    },
    isSubmitDirty,
    handleSubmit,
    filterValues,
    handleFilterChange,
  };
};
