import React, { useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Chip,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  MenuItem,
  TextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { useTimeSeriesComparisons } from "./useTimeSeriesComparisons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TimeseriesCompareLineChart from "../../../components/graphs/TimeseriesCompareLineChart";
import Table from "../../../components/Table";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import LocationsFilter from "../../../components/filters/LocationsFilter";
import ParametersFilter from "../../../components/filters/ParametersFilter";
import {
  PARAMETERS_WITH_HOURLY_INTERVAL,
  PARAMETERS_WITH_MIN_MAX,
  SAVED_ANALYSES_FILTER_VALUES,
  STATISTIC_OPTIONS,
  TIME_INTERAVAL_OPTIONS,
} from "./constants";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Accordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const AxisFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const SmallTextField = styled(TextField)`
  width: 250px;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-bottom: 2rem;
`;

const DEFAULT_STATISTIC_HELPER_TEXT =
  "Min and Max are only available for air temperature, stream temperature, and stream flow.";

export const StatisticsFilter = ({
  disabled,
  helperText = DEFAULT_STATISTIC_HELPER_TEXT,
  label = "Statistic",
  onChange,
  options = STATISTIC_OPTIONS,
  value = "avg",
}) => {
  return (
    <SmallTextField
      disabled={disabled}
      variant="outlined"
      helperText={helperText}
      select
      label={label}
      value={value}
      onChange={onChange}
      height="200px"
    >
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SmallTextField>
  );
};

const TimeIntervalFilter = ({
  disabled,
  onChange,
  value = "daily",
  size = "small",
}) => {
  return (
    <SmallTextField
      disabled={disabled}
      variant="outlined"
      helperText={
        "Heads up! Hourly data is only available for stream temperature and stream flow."
      }
      select
      label={"Time Interval"}
      value={value}
      size={size}
      onChange={onChange}
      // height="200px"
    >
      {TIME_INTERAVAL_OPTIONS.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SmallTextField>
  );
};

function isSubmitDisabled(filterValues) {
  const {
    locationsLeft,
    locationsRight,
    parameterLeft,
    parameterRight,
    startDate,
    endDate,
  } = filterValues;

  const hasLocations = locationsLeft?.length > 0 || locationsRight?.length > 0;
  const hasParameters = parameterLeft?.length > 0 || parameterRight?.length > 0;
  const hasStartDate = !!startDate;
  const hasEndDate = !!endDate;

  const hasTooManyLocations =
    locationsLeft?.length + locationsRight?.length > 20;

  return (
    !hasLocations ||
    !hasParameters ||
    hasTooManyLocations ||
    !hasStartDate ||
    !hasEndDate
  );
}

function TimeSeriesComparisons() {
  const saveRef = useRef(null);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const {
    data,
    tableColumns,
    graphData,
    filterValues,
    setFilterValues,
    handleFilterChange,
  } = useTimeSeriesComparisons();

  const handleSelectLeftLocations = (locations) => {
    setSelectedAnalysis(null);
    handleFilterChange(
      "locationsLeft",
      locations?.map((l) => l.location_index)
    );
  };

  const handleSelectRightLocations = (locations) => {
    setSelectedAnalysis(null);
    handleFilterChange(
      "locationsRight",
      locations?.map((l) => l.location_index)
    );
  };

  const handleSelectLeftParameter = (parameter) => {
    setSelectedAnalysis(null);
    handleFilterChange(
      "parameterLeft",
      parameter?.map((p) => p.parameter_index)
    );

    const isParameterWithHourlyInterval = parameter.some((p) =>
      PARAMETERS_WITH_HOURLY_INTERVAL.includes(p.parameter_index)
    );

    if (!isParameterWithHourlyInterval) {
      handleFilterChange("timeInterval", "daily");
    }
  };

  const handleSelectRightParameter = (parameter) => {
    setSelectedAnalysis(null);
    handleFilterChange(
      "parameterRight",
      parameter?.map((p) => p.parameter_index)
    );

    const isParameterWithHourlyInterval = parameter.some((p) =>
      PARAMETERS_WITH_HOURLY_INTERVAL.includes(p.parameter_index)
    );

    if (!isParameterWithHourlyInterval) {
      handleFilterChange("timeInterval", "daily");
    }
  };

  const handleSelectAnalysis = (analysis) => {
    setSelectedAnalysis(analysis);
    const analysisFilterValues = SAVED_ANALYSES_FILTER_VALUES[analysis];

    if (!!analysisFilterValues) {
      setFilterValues(analysisFilterValues);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Time Series Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Time Series Comparisons
      </Typography>
      <Divider my={6} />
      <Typography variant="h4" gutterBottom>
        Saved Analysis{" "}
      </Typography>
      <Typography mb={4}>
        Get a head start by selecting a pre-configured analysis scenario.
      </Typography>

      <ChipsContainer>
        <Chip
          onClick={() => handleSelectAnalysis("nfm")}
          color={"primary"}
          label={"North Fork Metals"}
          variant={selectedAnalysis === "nfm" ? "default" : "outlined"}
        />
        <Chip
          onClick={() => handleSelectAnalysis("rcu")}
          color={"primary"}
          label={"Ralston Creek Uranium"}
          variant={selectedAnalysis === "rcu" ? "default" : "outlined"}
        />
        <Chip
          onClick={() => handleSelectAnalysis("hst")}
          color={"primary"}
          label={"Hourly Stream Temp"}
          variant={selectedAnalysis === "hst" ? "default" : "outlined"}
        />
      </ChipsContainer>

      <Grid container spacing={3}>
        {!data.isLoadingOptions && (
          <>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={10}>
                    Time Period Filters
                  </Typography>
                </AccordionSummary>
                <Box mx={2}>
                  <AccordionDetails>
                    <FilterContainer>
                      <FilterRow>
                        <FormControl component="fieldset">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              label={"Start Date"}
                              format="MM/dd/yyyy"
                              id="start-date-picker"
                              value={filterValues.startDate}
                              onChange={(date) =>
                                handleFilterChange("startDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change start date",
                              }}
                              size="medium"
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl component="fieldset">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              label={"End Date"}
                              id="end-date-picker"
                              value={filterValues.endDate}
                              onChange={(date) =>
                                handleFilterChange("endDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change end date",
                              }}
                              size="medium"
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                        <TimeIntervalFilter
                          onChange={(e) =>
                            handleFilterChange("timeInterval", e.target.value)
                          }
                          size={"medium"}
                          value={filterValues?.timeInterval}
                        />
                      </FilterRow>
                    </FilterContainer>

                    <FilterContainer>
                      <Typography variant="h4" mb={4}>
                        Left Axis Filters
                      </Typography>
                      <AxisFilterRow>
                        <ParametersFilter
                          defaultValue={filterValues?.parameterLeft}
                          defaultFilterValues={{
                            timeInterval: filterValues?.timeInterval,
                          }}
                          onSelect={handleSelectLeftParameter}
                          isMultiSelect={false}
                        />
                        <LocationsFilter
                          onSelect={handleSelectLeftLocations}
                          defaultFilterValues={{
                            parameters: filterValues?.parameterLeft,
                            startDate: filterValues?.startDate,
                            endDate: filterValues?.endDate,
                          }}
                          defaultValue={filterValues?.locationsLeft}
                        />
                        <StatisticsFilter
                          disabled={
                            !filterValues?.parameterLeft?.some((p) =>
                              PARAMETERS_WITH_MIN_MAX.includes(p)
                            )
                          }
                          onChange={(e) =>
                            handleFilterChange("statisticLeft", e.target.value)
                          }
                          value={filterValues?.statisticLeft}
                        />
                      </AxisFilterRow>
                    </FilterContainer>

                    <FilterContainer>
                      <Typography variant="h4" mb={4}>
                        Right Axis Filters
                      </Typography>
                      <AxisFilterRow>
                        <ParametersFilter
                          defaultValue={filterValues?.parameterRight}
                          defaultFilterValues={{
                            timeInterval: filterValues?.timeInterval,
                          }}
                          onSelect={handleSelectRightParameter}
                          isMultiSelect={false}
                        />
                        <LocationsFilter
                          onSelect={handleSelectRightLocations}
                          defaultFilterValues={{
                            parameters: filterValues?.parameterRight,
                            startDate: filterValues?.startDate,
                            endDate: filterValues?.endDate,
                          }}
                          defaultValue={filterValues?.locationsRight}
                        />
                        <StatisticsFilter
                          disabled={
                            !filterValues?.parameterRight?.some((p) =>
                              PARAMETERS_WITH_MIN_MAX.includes(p)
                            )
                          }
                          onChange={(e) =>
                            handleFilterChange("statisticRight", e.target.value)
                          }
                          value={filterValues?.statisticRight}
                        />
                      </AxisFilterRow>
                    </FilterContainer>

                    <SubmitGrid item container>
                      <Grid item style={{ width: "calc(100% - 162px)" }}>
                        {!data?.graph?.data && (
                          <Alert severity="info">
                            After selecting your timeseries inputs, click the
                            'Submit' button to load an interactive timeseries
                            plot for comparison across different locations and
                            parameters.
                          </Alert>
                        )}
                        {(data?.graph?.data?.leftAxis?.length === 0 ||
                          data?.graph?.data?.rightAxis?.length === 0) && (
                          <Alert severity="warning">
                            No data available for{" "}
                            {data?.graph?.data?.leftAxis?.length === 0 &&
                            data?.graph?.data?.rightAxis?.length === 0
                              ? "left or right"
                              : data?.graph?.data?.leftAxis?.length === 0
                              ? "left"
                              : "right"}{" "}
                            axis
                          </Alert>
                        )}
                      </Grid>
                      <Grid item>
                        <SaveGraphButton
                          ref={saveRef}
                          title="Timeseries Comparison Graph"
                          disabled={data.graph.isLoading || !data.graph.data}
                        />
                        <Button
                          onClick={() => data.graph.refetch()}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          style={{ marginLeft: "10px" }}
                          disabled={isSubmitDisabled(filterValues)}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </SubmitGrid>
                  </AccordionDetails>
                </Box>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {!data.isLoadingOptions && !data.graph.isLoading && (
        <>
          {data.graph.data && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Graph
                </Typography>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <TimeseriesContainer>
                    <TableWrapper>
                      <TimeseriesCompareLineChart
                        data={graphData}
                        error={data.graph.error}
                        isLoading={data.graph.isLoading}
                        filterValues={filterValues}
                        yLLabel={graphData?.yLLabel}
                        yRLabel={graphData?.yRLabel}
                        xLabelUnit={
                          filterValues?.timeInterval === "hourly"
                            ? "hour"
                            : "week"
                        }
                        xLabelFormat={
                          filterValues?.timeInterval === "hourly"
                            ? "MM-DD-YYYY HH:mm"
                            : "MM-DD-YYYY"
                        }
                        ref={saveRef}
                        tooltipFormat={
                          filterValues?.timeInterval === "hourly"
                            ? "MM-DD-YYYY HH:mm"
                            : "MM-DD-YYYY"
                        }
                        footerLabel="Hours Pumped"
                      />
                    </TableWrapper>
                  </TimeseriesContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
          {data.graph.data && !data.graph.isLoading && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  Table
                </Typography>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <TableWrapper>
                    <Table
                      // isLoading={isLoading}
                      label="Time Series Compare Table"
                      columns={tableColumns}
                      data={[
                        ...[].concat.apply([], data?.graph?.data?.leftAxis),
                        ...[].concat.apply([], data?.graph?.data?.rightAxis),
                      ]}
                      height="590px"
                    />
                  </TableWrapper>
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default TimeSeriesComparisons;
