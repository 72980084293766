import { Bar } from "react-chartjs-2";
import React from "react";
import { DEFAULT_LINE_COLORS } from "../../utils";
import Loader from "../Loader";
import { Box, Typography } from "@material-ui/core";

const SiteStatisticsBarChart = React.forwardRef(
  ({ data, error, isLoading, yLLabel }, ref) => {
    const plugins = [
      {
        id: "chartFillBackground",
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, chart.width, chart.height);
        },
      },
    ];
    const options = {
      interaction: {
        intersect: false,
        mode: "x",
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Month",
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          ticks: {
            color: DEFAULT_LINE_COLORS.darkGray,
          },
        },
        yL: {
          position: "left",
          title: {
            display: true,
            text: yLLabel,
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          ticks: {
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          grid: {
            borderDash: [5, 5],
            drawBorder: true,
            drawTicks: true,
          },
        },
      },
      plugins: {
        tooltip: {
          // mode: "label",
          callbacks: {
            label: function (tooltipItems) {
              return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue} ${tooltipItems.dataset.units}`;
            },
          },
        },
      },
    };

    if (error) return "An error has occurred: " + error.message;

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.datasets?.length > 0 ? (
              <Bar ref={ref} data={data} options={options} plugins={plugins} />
            ) : (
              <Box
                height={200}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>No Data Available</Typography>
              </Box>
            )}
          </>
        )}
      </>
    );
  }
);

export default SiteStatisticsBarChart;
