import axios from "axios";
import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import {
  dateFormatter,
  groupByValueArray,
  DEFAULT_LINE_COLORS,
} from "../../../utils";
import { lighten } from "@material-ui/core";
import moment from "moment";

export const useTimeSeriesComparisons = () => {
  //UseQuery calls using the useDataQuery custom hook
  const {
    data: dataGraph,
    error: errorGraph,
    isLoading: isLoadingGraph,
    refetch: refetchGraph,
  } = useQuery(
    ["ts-daily-graph-data"],
    async () => {
      try {
        const parameterLeftIndex = filterValues.parameterLeft?.[0] || -1;
        const parameterRightIndex = filterValues.parameterRight?.[0] || -1;

        const locationLeftIndexes =
          filterValues.locationsLeft?.join(",") || "-1";

        const locationRightIndexes =
          filterValues.locationsRight?.join(",") || "-1";

        const startDate = moment(filterValues.startDate).format("YYYY-MM-DD");
        const endDate = moment(filterValues.endDate).format("YYYY-MM-DD");

        const leftStatistic = filterValues.statisticLeft;
        const rightStatistic = filterValues.statisticRight;

        const timeInterval = filterValues.timeInterval;

        const { data: dataLeftAxis } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-graph-data/${parameterLeftIndex}/${locationLeftIndexes}/${startDate}/${endDate}/${leftStatistic}/${timeInterval}`
        );

        const { data: dataRightAxis } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-graph-data/${parameterRightIndex}/${locationRightIndexes}/${startDate}/${endDate}/${rightStatistic}/${timeInterval}`
        );

        return {
          leftAxis: groupByValueArray(dataLeftAxis, "location_index"),
          rightAxis: groupByValueArray(dataRightAxis, "location_index"),
        };
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (dataGraph) {
      let count = -1;

      const leftStatistic = filterValues.statisticLeft;
      const rightStatistic = filterValues.statisticRight;
      const prettyLeftStatistic =
        leftStatistic.charAt(0).toUpperCase() + leftStatistic.slice(1);
      const prettyRightStatistic =
        rightStatistic.charAt(0).toUpperCase() + rightStatistic.slice(1);

      const graphData = {
        yLLabel: dataGraph?.leftAxis?.length
          ? `${dataGraph?.leftAxis[0][0]?.parameter} (${dataGraph?.leftAxis[0][0]?.units})`
          : null,
        yRLabel: dataGraph?.rightAxis?.length
          ? `${dataGraph?.rightAxis[0][0]?.parameter} (${dataGraph?.rightAxis[0][0]?.units})`
          : null,
        datasets: [
          ...(dataGraph?.rightAxis?.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.collect_date,
                  y: item.result,
                };
              }),
              yAxisID: "yR",
              units: location[0].units,
              pointStyle: "triangle",
              borderWidth: 2,
              borderCapStyle: "round",
              borderDash: [8, 10],
              pointRadius: 3,
              pointHoverRadius: 4,
              label: `${prettyRightStatistic} - ${location[0].location_name} (right axis / ${location[0].units})`,
              borderColor: Object.values(DEFAULT_LINE_COLORS)[count],
              backgroundColor: lighten(
                Object.values(DEFAULT_LINE_COLORS)[count],
                0.5
              ),
              tension: 0.5,
              showLine: !![10, 11, 13, 14].includes(location[0].source_index),
            };
          }) || []),
          ...(dataGraph?.leftAxis?.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.collect_date,
                  y: item.result,
                };
              }),
              yAxisID: "yL",
              units: location[0].units,
              pointStyle: "rect",
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 4,
              label: `${prettyLeftStatistic} - ${location[0].location_name} (left axis / ${location[0].units})`,
              borderColor: Object.values(DEFAULT_LINE_COLORS)[count],
              backgroundColor: lighten(
                Object.values(DEFAULT_LINE_COLORS)[count],
                0.5
              ),
              tension: 0.5,
              showLine: !![10, 11, 13, 14].includes(location[0].source_index),
            };
          }) || []),
        ],
      };
      setGraphData(graphData);
    }
  }, [dataGraph]); //eslint-disable-line

  const tableColumns = [
    { title: "Parameter Category", field: "parameter_category" },
    { title: "Parameter Name", field: "parameter" },
    { title: "Location", field: "location_id" },
    {
      title: "Location Name",
      field: "location_name",
      render: (rowData) => {
        return rowData.location_id
          ? `${rowData.location_id} - ${rowData.location_name}`
          : rowData.location_name;
      },
    },
    {
      title: "Result",
      field: "result",
      render: (rowData) => {
        return rowData?.result?.toFixed(2);
      },
    },
    { title: "Units", field: "units" },
    {
      title: "Collection Date",
      field: "collect_date",
      render: (rowData) => {
        const hasTimestamp = !!rowData.collect_timestamp;
        const dateFormat = hasTimestamp ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY";
        return dateFormatter(rowData.collect_date, dateFormat);
      },
    },
    { title: "Source", field: "source" },
    { title: "Basin", field: "basin" },
    { title: "Season", field: "season" },
    { title: "Huc12", field: "huc12" },
    { title: "Organization", field: "organization" },
  ];

  //---------------------

  const [filterValues, setFilterValues] = useState({
    //two years ago from this years 10/1
    startDate: new Date(new Date().getFullYear() - 2, 9, 1),
    endDate: new Date(),
    locationsLeft: [],
    locationsRight: [],
    parameterLeft: [],
    parameterRight: [],
    statisticLeft: "avg",
    statisticRight: "avg",
    timeInterval: "daily",
  });

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  return {
    data: {
      graph: {
        data: dataGraph,
        isLoading: isLoadingGraph,
        error: errorGraph,
        refetch: refetchGraph,
      },
    },
    graphData,
    tableColumns,
    filterValues,
    setFilterValues,
    handleFilterChange,
  };
};
