import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 48px - 48px - 64px);
  position: relative;
`;

const BenchmarkDocumentation = () => {
  return (
    <>
      <Helmet title="Benchmark Documentation" />

      <Container>
        <embed
          src={"/benchmarks.pdf"}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          title="Benchmark Documentation"
        />
      </Container>
    </>
  );
};

export default BenchmarkDocumentation;
