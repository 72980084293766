import axios from "axios";
import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import {
  dateFormatter,
  groupByValueArray,
  DEFAULT_LINE_COLORS,
} from "../../../utils";
import { lighten } from "@material-ui/core";
import moment from "moment";

export const useParameterComparisonScatterPlot = () => {
  //UseQuery calls using the useDataQuery custom hook
  const {
    data: dataGraph,
    error: errorGraph,
    isLoading: isLoadingGraph,
    refetch: refetchGraph,
  } = useQuery(
    ["ts-daily-graph-data"],
    async () => {
      try {
        const parameterYIndex = filterValues.parameterY?.[0] || -1;
        const parameterXIndex = filterValues.parameterX?.[0] || -1;

        const locationXIndexes = filterValues.locationsX?.join(",") || "-1";
        const locationYIndexes = filterValues.locationsY?.join(",") || "-1";

        const startDate = moment(filterValues.startDate).format("YYYY-MM-DD");
        const endDate = moment(filterValues.endDate).format("YYYY-MM-DD");

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/parameter-comparison-scatter-plot/${locationXIndexes}/${locationYIndexes}/${parameterXIndex}/${parameterYIndex}/${startDate}/${endDate}`
        );

        return {
          leftAxis: groupByValueArray(data, "series_name"),
        };
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    // data?.leftAxis?.length && data?.xAxis?.length
    if (dataGraph) {
      let count = -1;
      const graphData = {
        xLabel: dataGraph?.leftAxis?.length
          ? `${dataGraph?.leftAxis?.[0][0].x_parameter_name} / ${dataGraph?.leftAxis?.[0][0].x_units}`
          : null,
        yLLabel: dataGraph?.leftAxis?.length
          ? `${dataGraph?.leftAxis?.[0][0].y_parameter_name} / ${dataGraph?.leftAxis?.[0][0].y_units}`
          : null,
        datasets: [
          ...(dataGraph?.leftAxis?.map((series) => {
            count++;
            return {
              data: series.map((item) => {
                return {
                  x: item.x_result,
                  y: item.y_result,
                };
              }),
              parameterY: series[0].y_parameter_name,
              parameterX: series[0].x_parameter_name,
              unitsY: series[0].y_units,
              unitsX: series[0].x_units,
              pointStyle: "circle",
              borderWidth: 2,
              pointRadius: 4,
              pointHoverRadius: 8,
              date: series.map((item) => item.collect_date),
              label: series[0].series_name,
              borderColor: Object.values(DEFAULT_LINE_COLORS)[count],
              backgroundColor: lighten(
                Object.values(DEFAULT_LINE_COLORS)[count],
                0.5
              ),
            };
          }) || []),
        ],
      };
      setGraphData(graphData);
    }
  }, [dataGraph]);

  const tableColumns = [
    {
      title: "Collection Date",
      field: "collect_date",
      render: (rowData) => {
        return dateFormatter(rowData.collect_date, "MM/DD/YYYY");
      },
    },
    { title: "Series Name", field: "series_name" },
    { title: "X Parameter", field: "x_parameter_name" },
    { title: "X Result", field: "x_result" },
    { title: "X Units", field: "x_units" },
    { title: "Y Parameter", field: "y_parameter_name" },
    { title: "Y Result", field: "y_result" },
    { title: "Y Units", field: "y_units" },
  ];

  //---------------------

  const [filterValues, setFilterValues] = useState({
    //two years ago from this years 10/1
    startDate: new Date(new Date().getFullYear() - 2, 9, 1),
    endDate: new Date(),
    locationsX: [],
    locationsY: [],
    parameterY: [],
    parameterX: [],
  });

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  return {
    data: {
      graph: {
        data: dataGraph,
        isLoading: isLoadingGraph,
        error: errorGraph,
        refetch: refetchGraph,
      },
    },
    graphData,
    tableColumns,
    filterValues,
    handleFilterChange,
  };
};
