import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  CardContent,
  Card as MuiCard,
  CardHeader,
  CardMedia,
  Box,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { customSecondary, customHighlight } from "../../../theme/variants";
import { DisclaimerText } from "../../../components/DisclaimerText";

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;
const About = styled.section`
  border-top: 5px solid #424242;
  margin-top: 12px;
  margin-bottom: 24px;
  background-color: ${() => customHighlight[500]};
`;

const Hero = styled.div`
  background: url("/static/img/hdr-about-Denver-Water2.jpg") center center /
    cover no-repeat;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Recap = styled.section`
  border-top: 5px solid ${() => customHighlight[500]};
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Description = styled.div`
  background-color: ${({ color = customHighlight[500] }) => color};
  color: white;
  padding: 20px 40px;
`;

const Welcome = styled.div`
  height: 250px;
  background-color: rgba(47, 44, 116, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 26px;
  border-radius: 50%;
  position: absolute;
`;

const StoryCard = styled(Card)`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35) !important;

  &:hover {
    outline: 4px solid ${() => customSecondary[500]};

    & .title-highlight {
      color: ${() => customSecondary[500]};
    }
  }
`;

const WelcomeLink = styled(Link)`
  color: ${() => customHighlight[500]};
  font-weight: 900;
`;

const AboutLink = styled(Link)`
  color: #424242;
  font-weight: 900;
`;

function Default() {
  const { user } = useAuth0();

  return (
    <React.Fragment>
      <Helmet title="Denver Water Watershed Water Quality Dashboard" />
      <Hero>
        <Welcome>
          <Typography variant="h3" gutterBottom style={{ color: "azure" }}>
            Welcome to the
          </Typography>
          <Typography variant="h2" gutterBottom style={{ color: "azure" }}>
            Denver Water Watershed Water Quality Dashboard
          </Typography>

          <Typography variant="subtitle1" style={{ color: "azure" }}>
            {user?.name}
          </Typography>
        </Welcome>
      </Hero>

      <DisclaimerText>
        This tool is for internal Denver Water use only. Not all data is
        classified as public information. Please contact Alison Witheridge in
        Watershed Planning before providing data externally.
      </DisclaimerText>

      <Recap>
        <Grid container>
          <Grid item xs={12} lg={4} style={{ backgroundColor: "white" }}>
            <Link
              href="https://www.denverwater.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={"/static/img/dw-logo-large.png"}
                style={{
                  margin: "auto",
                  display: "block",
                  maxWidth: "100%",
                  // objectFit: "cover",
                  objectFit: "contain",
                  height: "100%",
                }}
                alt="Water Waves"
              />
            </Link>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Description color="#424242">
              <Typography
                variant="h3"
                style={{ fontWeight: "100", fontSize: "30px" }}
              >
                Welcome to the Dashboard!
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                Denver Water provides safe, high-quality water and works hard to
                ensure a reliable supply for the future. This Dashboard brings
                together data from multiple sources to make water quality
                information more accessible and usable. This tool represents a
                snapshot of conditions for Denver Water's source water for a
                variety of water quality parameters. Explore water quality
                monitoring locations, trends, and statistics in the{" "}
                <WelcomeLink component={NavLink} to="/data-access/map-explorer">
                  Map Explorer
                </WelcomeLink>
                , or dig deeper with data visualizations by navigating to Graph
                Mode within the Map Explorer. Additional tools include the{" "}
                <WelcomeLink
                  component={NavLink}
                  to="/data-access/query-and-download"
                >
                  Query & Download Page
                </WelcomeLink>
                , the{" "}
                <WelcomeLink
                  component={NavLink}
                  to="/data-access/water-quality-report-card"
                >
                  Water Quality Report Card
                </WelcomeLink>{" "}
                , the{" "}
                <WelcomeLink
                  component={NavLink}
                  to="/data-access/reservoir-portal-tool"
                >
                  Reservoir Portal Tool
                </WelcomeLink>
                , and the{" "}
                <WelcomeLink
                  component={NavLink}
                  to="/data-access/time-series-comparisons"
                >
                  Time Series Comparisons
                </WelcomeLink>
                .
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                The primary data sources for this tool are Denver Water LIMS,
                Colorado Data Sharing Network (CDSN), Colorado Division of Water
                Resources (DWR), and the National Water Quality Portal. The
                Denver Water LIMS data is authorized data from the Oracle “sdwp”
                database DW_INTERNAL_LW_RESULTS_VW. The data is then entered
                into a Postgres database for processing. The Postgres database
                standardizes parameter names and units, removes duplicate data,
                and classifies monitoring points and results based on attributes
                important to Denver Water. Denver Water LIMS data is updated
                monthly. CDSN/National WQ Portal data is updated daily.
              </Typography>
            </Description>
          </Grid>
        </Grid>
      </Recap>

      <About>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={12} lg={8}>
            <Description color={customHighlight[500]}>
              <Typography
                variant="h3"
                style={{ fontWeight: "100", fontSize: "30px" }}
              >
                About the Tool
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                <AboutLink component={NavLink} to="/data-access/map-explorer">
                  Map Explorer & Graph Mode
                </AboutLink>
                <br />
                View and filter monitoring locations across the North and South
                Systems based on location types, water quality sources and/or
                basins along with other map layers such as wildfires, land
                ownership, and HUC boundaries. Use the Graph Mode to explore
                water quality results compared to benchmark values for selected
                risks and associated parameters for your selected time period
                and flow regime. Drill down to view monitoring point statistics,
                trends, and concentrations over time.
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                <AboutLink
                  component={NavLink}
                  to="/data-access/query-and-download"
                >
                  Query & Download
                </AboutLink>
                <br />
                Export water quality data based on a variety of selections,
                including locations, parameters, values, risks, collection
                systems, data sources, and/or basin.
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                <AboutLink
                  component={NavLink}
                  to="/data-access/water-quality-report-card"
                >
                  Water Quality Report Card
                </AboutLink>
                <br />
                Calculate potential current and future risks based on benchmark
                results and trends for selected parameters for the Assessment
                Basins throughout the collection system.
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                <AboutLink
                  component={NavLink}
                  to="/data-access/reservoir-portal-tool"
                >
                  Reservoir Portal Tool
                </AboutLink>
                <br />
                Investigate reservoir depth profile and sampling results over
                time for eleven reservoirs throughout the collection system.
                Compare temperature, dissolved oxygen, nutrients, metals, and
                other parameters to standards and benchmarks to explore changes
                over time and depth.
              </Typography>

              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "15px",
                  lineHeight: "1.8",
                  fontSize: ".95rem",
                }}
              >
                <AboutLink
                  component={NavLink}
                  to="/data-access/time-series-comparisons"
                >
                  Time Series Comparisons
                </AboutLink>
                <br />
                Compare data by time period, basin, data source, location, and
                parameters through an interactive time series plot. Colorado DWR
                data is calculated daily for average, minimum, and maximum
                values.
              </Typography>
            </Description>
          </Grid>
          <Grid item xs={12} lg={4} align="center">
            <img
              src={"/static/img/denver-letter-logo.png"}
              style={{
                margin: "auto",
                display: "block",
              }}
              alt="Water Letter Logo"
            />
          </Grid>
        </Grid>
      </About>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <Link
            component={NavLink}
            to="/data-access/map-explorer"
            underline="none"
          >
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title={
                  <Box display="flex" justifyContent="space-between">
                    <span>Map Explorer</span>
                    <span>- & -</span>
                    <span>Graph Mode</span>
                  </Box>
                }
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/map-explorer.JPG"
                    title="Map Explorer"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Link
            component={NavLink}
            to="/data-access/query-and-download"
            underline="none"
          >
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Query & Download"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/query-and-download.JPG"
                    title="Query & Download"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Link
            component={NavLink}
            to="/data-access/water-quality-report-card"
            underline="none"
          >
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Water Quality Report Card"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/water-quality-report-card.JPG"
                    title="Water Quality Report Card"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Link
            component={NavLink}
            to="/data-access/reservoir-portal-tool"
            underline="none"
          >
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Reservoir Portal Tool"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/reservoir-portal-tool.JPG"
                    title="Reservoir Portal Tool"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Link
            component={NavLink}
            to="/data-access/time-series-comparisons"
            underline="none"
          >
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Time Series Comparisons"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/time-series-comparisons.JPG"
                    title="Time Series Comparisons"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
