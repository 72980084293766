import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Paper } from "@material-ui/core";
import "./styles.css";

import Map from "./map";
import ZoomInfo from "./controls/zoomInfo";
import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";

import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";
import GraphModeToggle from "./controls/graphModeToggle";

import FiltersBar from "./components/FiltersBar";
import FiltersBarGraphMode from "./components/FiltersBarGraphMode";
import GraphModeControl from "./controls/graphModeControl";
import Search from "./filters/search";
import useGraphMode from "./hooks/useGraphMode";
import DataViz from "./components/DataViz";
import DataVizControl from "./controls/dataVizControl";
import Legend from "./components/Legend";
import LegendControl from "./controls/LegendControl";
import GraphModeLayersToggle from "./controls/GraphModeLayersToggle";
import GraphModePopupToggle from "./controls/GraphModePopupToggle";
import Loader from "../../components/Loader";
import UpdateFrequencyToggle from "./controls/UpdateFrequencyToggle";
import UpdateFrequencyPopup from "../../components/map/components/UpdateFrequencyPopup";
import { downloadCsvFromLink } from "../../utils";

const FiltersBarRoot = styled(Paper)`
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-grow: 100;
`;

const PublicMap = () => {
  const mapContainer = useRef(null);
  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    zoomLevel,
    searchRadiusBuffers,
    resetSearchRadiusBuffers,
    handleClearSearchRadiusBuffers,
    handleEnableSearchRadiusControl,
    updateSearchRadiusBuffers,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    eventsRegistered,
    lastLocationIdClicked,
    setLastLocationIdClicked,
    dataVizVisible,
    setDataVizVisible,
    graphModeVisible,
    setGraphModeVisible,
    isMapLoaded,
    updateFrequencyVisible,
    setUpdateFrequencyVisible,
  } = useMap(mapContainer, INIT_MAP_CONFIG);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    setFilterValues,
    handleResetAll,
  } = useFilters({ onFilterChange: updateLayerFilters, isMapLoaded });

  const {
    filterValuesGraphMode,
    analysisTypes,
    seasons,
    parameterCategories,
    priorityTypes,
    threats,
    parameters,
    handleFilterValuesGraphMode,
    onSelectAllParameters,
    onSelectNoneParameters,
    handleGraphModeClick,
    hasGraphDataLoaded,
    analyticsResults,
    timeSeriesResults,
    isTimeSeriesResultsLoading,
    getHexColorForScore,
    isAnalyticsTableDataLoading,
    legendVisible,
    setLegendVisible,
    graphModeBenchmarkColors,
    handleGraphModeLayersToggleClick,
    graphModeLayersVisible,
    graphModePopupVisible,
    setGraphModePopupVisible,
    inputValue,
    setInputValue,
    handleExportClick,
    handleControlsSubmit,
    isSubmitLoading,
    isExportLoading,
  } = useGraphMode({
    map,
    updateLayerFilters,
    updateLayerStyles,
    layers,
    setDataVizVisible,
    lastLocationIdClicked,
    setLastLocationIdClicked,
    graphModeVisible,
    setGraphModeVisible,
    setFilterValues,
  });

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = [
    "Print PDF",
    "Save PNG",
    "Export Time Series",
    "Export Stats & Benchmarks",
    "Reservoir Profile Data",
  ];
  const handleSplitButtonClick = (index) => {
    if (index === 3) {
      handleExportClick(index);
      return;
    }

    if (index === 4) {
      downloadCsvFromLink(
        "https://lrecloud.com/appdata/reservoir_tool_output.csv",
        "reservoir_tool_output.csv"
      );
    }

    if (index === 2) {
      window.open("/data-access/query-and-download", "_blank");
    }

    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleSearchSelect = (result) => {
    map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
  };

  return (
    <>
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}

      {process.env.NODE_ENV === "development" && (
        <ZoomInfo zoomLevel={zoomLevel} />
      )}

      <FiltersBarRoot>
        <FiltersContainer>
          <FiltersSectionRow>
            <Search onSelect={handleSearchSelect} />
          </FiltersSectionRow>
        </FiltersContainer>
        {graphModeVisible && (
          <FiltersBarGraphMode
            isSubmitLoading={isSubmitLoading}
            handleControlsSubmit={handleControlsSubmit}
            filterValues={filterValuesGraphMode}
            analysisTypes={analysisTypes}
            seasons={seasons}
            handleFilterValues={handleFilterValuesGraphMode}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
        <FiltersBar
          filterValues={filterValues}
          handleFilterValues={handleFilterValues}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          updateLayerStyles={updateLayerStyles}
          graphModeVisible={graphModeVisible}
          handleResetAll={handleResetAll}
        />

        <FiltersSection>
          <FiltersContainer>
            <>
              {isExportLoading ? (
                <div style={{ width: "262px" }}>
                  <Loader />
                </div>
              ) : (
                <SplitButton
                  options={splitButtonOptions}
                  handleExportClick={handleSplitButtonClick}
                  graphModeVisible={graphModeVisible}
                />
              )}

              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>
      <Map ref={mapContainer}>
        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />
        {eventsRegistered && graphModeVisible ? (
          <GraphModeControl
            filterValues={filterValuesGraphMode}
            handleFilterValues={handleFilterValuesGraphMode}
            parameterCategories={parameterCategories}
            priorityTypes={priorityTypes}
            threats={threats}
            parameters={parameters}
            onSelectAllParameters={onSelectAllParameters}
            onSelectNoneParameters={onSelectNoneParameters}
          />
        ) : (
          <MainControl
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            bufferValues={searchRadiusBuffers}
            layers={layers}
            onBasemapChange={updateBasemap}
            onBufferValuesChange={updateSearchRadiusBuffers}
            onClearBuffers={handleClearSearchRadiusBuffers}
            onEnableSearchRadiusControl={handleEnableSearchRadiusControl}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            onResetBuffers={resetSearchRadiusBuffers}
          />
        )}
        {graphModeVisible && (
          <DataViz
            open={dataVizVisible}
            onClose={() => setDataVizVisible(false)}
            analyticsResults={analyticsResults}
            timeSeriesResults={timeSeriesResults}
            filterValues={filterValuesGraphMode}
            isTimeSeriesResultsLoading={isTimeSeriesResultsLoading}
            getHexColorForScore={getHexColorForScore}
            isAnalyticsTableDataLoading={isAnalyticsTableDataLoading}
            lastLocationIdClicked={lastLocationIdClicked}
          />
        )}

        {eventsRegistered && hasGraphDataLoaded && (
          <GraphModeToggle
            open={graphModeVisible}
            handleClick={handleGraphModeClick}
          />
        )}

        {!updateFrequencyVisible && (
          <UpdateFrequencyToggle
            handleClick={() =>
              setUpdateFrequencyVisible(!updateFrequencyVisible)
            }
          />
        )}

        {updateFrequencyVisible && (
          <UpdateFrequencyPopup
            onHide={() => setUpdateFrequencyVisible(false)}
          />
        )}

        {graphModeVisible && (
          <>
            <DataVizControl
              open={dataVizVisible}
              handleClick={() => setDataVizVisible(!dataVizVisible)}
            />
            <LegendControl
              open={legendVisible}
              onToggle={() => setLegendVisible(!legendVisible)}
            />
            {legendVisible && (
              <Legend legendColors={graphModeBenchmarkColors} />
            )}
            <GraphModeLayersToggle
              open={graphModeLayersVisible}
              onToggle={handleGraphModeLayersToggleClick}
            />
            <GraphModePopupToggle
              open={graphModePopupVisible}
              onToggle={() => setGraphModePopupVisible(!graphModePopupVisible)}
            />
            {!graphModePopupVisible && (
              <div
                dangerouslySetInnerHTML={{
                  __html: "<style>.mapboxgl-popup { display: none; }</style>",
                }}
              />
            )}
          </>
        )}

        {!measurementsVisible && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </>
  );
};

export default PublicMap;
