import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  Grid as MuiGrid,
  IconButton,
  isWidthUp,
  Switch,
  TextField,
  Tooltip,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import Chips from "../../../components/Chips";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useApp } from "../../../AppProvider";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import QueryAndDownloadMap from "../../../components/map/QueryAndDownloadMap";
import SplitButtonQueryAndDownload from "../../../components/SplitButtonQueryAndDownload";
import { RefreshCw } from "react-feather";
import UpdateFrequencyPopover from "../../../components/map/components/UpdateFrequencyPopover";
import { DisclaimerText } from "../../../components/DisclaimerText";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
  // color: ${(props) => props.theme.palette.common.white};
`;

const MapContainer = styled.div`
  height: 1070px;
  width: 100%;
`;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

const QueryAndDownload = ({ width }) => {
  const { doToast, currentUser } = useApp();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const initFilterValues = {
    parameterCategories: [],
    priorityTypes: [],
    threats: [],
    parameters: [],
    isAssessmentPoint: false,
    isTreatmentPlantSource: false,
    basins: [],
    locationTypes: [],
    sources: [],
    collectionAreas: [],
    locations: [],
    startDate: null,
    endDate: null,
    uuid: null,
    uuidOrDefault: null,
    currentUserFilters: {},
    splitButtonOptions: [],
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [recordCount, setRecordCount] = useState(null);

  const [userFilters] = useFetchData(
    "query-and-download/user-filters",
    [],
    false
  );

  useEffect(() => {
    if (currentUser) {
      handleFilterValues("uuid", currentUser.sub);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userFilters.length && filterValues.uuid) {
      let filters;
      let uuid;
      if (userFilters.find((user) => user.user_uuid === filterValues.uuid)) {
        filters = userFilters.find(
          (user) => user.user_uuid === filterValues.uuid
        );
        uuid = filterValues.uuid;
      } else {
        filters = userFilters.find((user) => user.user_uuid === "default");
        uuid = "default";
      }

      setFilterValues((prevState) => {
        return {
          ...prevState,
          startDate: convertUTCDateToLocalDate(new Date(filters.start_date)),
          endDate: convertUTCDateToLocalDate(new Date(filters.end_date)),
        };
      });

      handleFilterValues("currentUserFilters", filters);
      handleFilterValues(
        "splitButtonOptions",
        userFilters.filter((filter) => filter.quickset)
      );
      handleFilterValues("uuidOrDefault", uuid);
    }
  }, [userFilters, filterValues.uuid]); //eslint-disable-line

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  const getFilterIndexByName = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterField] === name);
    return filter[filterIndex];
  };

  const getFilterNameByIndex = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterIndex] === name);
    return filter[filterField];
  };

  const cleanFilter = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.indexOf(x[filterField]) !== -1) {
        newParams.push(x[filterField]);
      }
    });
    return newParams;
  };

  const cleanFilterLocations = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.find((item) => item[filterField] === x[filterField])) {
        newParams.push(x);
      }
    });
    return newParams;
  };

  const [parameterCategories] = useFetchData(
    "list-parameter-categories-graph-mode",
    [],
    false
  );
  const [priorityTypes] = useFetchData(
    "list-priority-types-graph-mode",
    [],
    false
  );
  const [threats] = useFetchData("list-threats-graph-mode", [], false);

  const [basins] = useFetchData("list-basins", [], false);
  const [locationTypes] = useFetchData("list-location-types", [], false);
  const [sources] = useFetchData("list-sources", [], false);
  const [collectionAreas] = useFetchData("list-coll-areas", [], false);

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (
      parameterCategories.length &&
      priorityTypes.length &&
      threats.length &&
      basins.length &&
      locationTypes.length &&
      sources.length &&
      collectionAreas.length
    ) {
      onFilterSelectAll(
        "parameterCategories",
        parameterCategories,
        "param_category_index"
      );
      onFilterSelectAll("priorityTypes", priorityTypes, "priority_type_index");
      onFilterSelectAll("threats", threats, "threat_index");
      onFilterSelectAll("basins", basins, "basin_ndx");
      onFilterSelectAll("locationTypes", locationTypes, "location_type");
      onFilterSelectAll("sources", sources, "source_index");
      onFilterSelectAll(
        "collectionAreas",
        collectionAreas,
        "dw_collection_area"
      );
      setHasFiltersLoaded(true);
    }
  }, [
    parameterCategories,
    priorityTypes,
    threats,
    basins,
    locationTypes,
    sources,
    collectionAreas,
  ]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    [
      "list-parameters",
      filterValues.parameterCategories,
      filterValues.priorityTypes,
      filterValues.threats,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/list-parameters`,
            {
              parameterCategories: filterValues.parameterCategories,
              priorityTypes: filterValues.priorityTypes,
              threats: filterValues.threats,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      parameters?.length &&
      !hasParametersLoaded &&
      filterValues.currentUserFilters
    ) {
      setHasParametersLoaded(true);

      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: filterValues.currentUserFilters.parameters
            .filter((p) => parameters.find((x) => x.parameter_index === p))
            .map((parameter) =>
              getFilterNameByIndex(
                parameter,
                parameters,
                "parameter_abbrev",
                "parameter_index"
              )
            ),
        };
      });
    }
  }, [parameters, userFilters, filterValues.uuid]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: cleanFilter(
            filterValues.parameters,
            parameters,
            "parameter_abbrev"
          ),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  const [hasLocationsLoaded, setHasLocationsLoaded] = useState(false);
  const { data: locations } = useQuery(
    [
      "list-locations",
      filterValues.basins,
      filterValues.locationTypes,
      filterValues.sources,
      filterValues.collectionAreas,
      filterValues.isAssessmentPoint,
      filterValues.isTreatmentPlantSource,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/list-locations`,
            {
              basins: filterValues.basins,
              locationTypes: filterValues.locationTypes,
              sources: filterValues.sources,
              collectionAreas: filterValues.collectionAreas,
              isAssessmentPoint: filterValues.isAssessmentPoint,
              isTreatmentPlantSource: filterValues.isTreatmentPlantSource,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      locations?.length &&
      !hasLocationsLoaded &&
      filterValues.currentUserFilters
    ) {
      setHasLocationsLoaded(true);

      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: locations.filter((p) =>
            filterValues.currentUserFilters.locations.includes(p.location_index)
          ),
        };
      });
    }
  }, [locations, userFilters, filterValues.uuid]); //eslint-disable-line

  useEffect(() => {
    if (hasLocationsLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: cleanFilterLocations(
            filterValues.locations,
            locations,
            "location_index"
          ),
        };
      });
    }
  }, [locations]); //eslint-disable-line

  const handleFilterValues = (name, value) => {
    if (
      ![
        "analysis",
        "season",
        "collectionSystem",
        "parameterCategories",
        "priorityTypes",
        "threats",
        "startDate",
        "endDate",
        "basins",
        "locationTypes",
        "sources",
        "collectionAreas",
        "locations",
        "isAssessmentPoint",
        "isTreatmentPlantSource",
        "uuid",
        "currentUserFilters",
        "uuidOrDefault",
        "splitButtonOptions",
      ].includes(name)
    ) {
      setFilterValues((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(value);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else if (
      [
        "parameterCategories",
        "priorityTypes",
        "threats",
        "basins",
        "locationTypes",
        "sources",
        "collectionAreas",
      ].includes(name)
    ) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmitFilters = () => {
    setIsSubmitLoading(true);
    return (async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/user-filters`,
          {
            user_uuid: filterValues.uuid,
            parameters: filterValues.parameters.map((parameter) =>
              getFilterIndexByName(
                parameter,
                parameters,
                "parameter_abbrev",
                "parameter_index"
              )
            ),
            locations: filterValues.locations.map(
              (location) => location.location_index
            ),
            start_date: filterValues.startDate,
            end_date: filterValues.endDate,
          }
        );

        handleFilterValues("uuidOrDefault", filterValues.uuid);

        doToast("success", "Your filters were successfully saved");
        setIsSubmitLoading(false);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsSubmitLoading(false);
      }
    })();
  };

  // if a new count request is made, the previous one is cancelled
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const fetchDataCount = async () => {
    const formData = {
      parameters: filterValues.parameters.map((parameter) =>
        getFilterIndexByName(
          parameter,
          parameters,
          "parameter_abbrev",
          "parameter_index"
        )
      ),
      locations: filterValues.locations.map(
        (location) => location.location_index
      ),
      start_date: filterValues.startDate,
      end_date: filterValues.endDate,
    };
    const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/count`;

    cancelTokenSourceRef.current.cancel("Cancelling previous request");
    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const { data } = await axios.post(url, formData, {
        cancelToken: cancelTokenSourceRef.current.token,
      });
      return data.count;
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Fetch error:", error);
      }
      throw error;
    }
  };

  const formValid = useMemo(
    () =>
      Boolean(
        filterValues.startDate &&
          filterValues.endDate &&
          filterValues.parameters.length &&
          filterValues.locations.length
      ),
    [
      filterValues.parameters,
      filterValues.locations,
      filterValues.startDate,
      filterValues.endDate,
    ]
  );

  useEffect(() => {
    if (!formValid) {
      setRecordCount(null);
    }
  }, [formValid]);

  const { isFetching: isLoadingCurrentCount } = useQuery(
    [
      "count",
      filterValues.startDate,
      filterValues.endDate,
      filterValues.parameters,
      filterValues.locations,
    ],
    fetchDataCount,
    {
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setRecordCount(data);
      },
      enabled: formValid,
      onError: (error) => {
        setRecordCount(null);
        console.error("Error fetching count:", error);
      },
    }
  );

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = () => {
    setIsExportLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/stream`,
      data: {
        parameters: filterValues.parameters.map((parameter) =>
          getFilterIndexByName(
            parameter,
            parameters,
            "parameter_abbrev",
            "parameter_index"
          )
        ),
        locations: filterValues.locations.map(
          (location) => location.location_index
        ),
        start_date: filterValues.startDate,
        end_date: filterValues.endDate,
      },
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Time_Series_Data.csv");
        document.body.appendChild(link);
        link.click();
        setIsExportLoading(false);
      })
      .catch((error) => {
        console.error("Download error:", error);
        setIsExportLoading(false);
      });
  };

  const [
    loadingQuickSetFilterForLocations,
    setLoadingQuickSetFilterForLocations,
  ] = useState(null);
  const [
    loadingQuickSetFilterForParameters,
    setLoadingQuickSetFilterForParameters,
  ] = useState(null);
  const handleSplitButtonClick = () => {
    setLoadingQuickSetFilterForLocations(true);
    setLoadingQuickSetFilterForParameters(true);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        isAssessmentPoint: false,
        isTreatmentPlantSource: false,
        parameterCategories: parameterCategories.map(
          (x) => x["param_category_index"]
        ),
        priorityTypes: priorityTypes.map((x) => x["priority_type_index"]),
        threats: threats.map((x) => x["threat_index"]),
        basins: basins.map((x) => x["basin_ndx"]),
        locationTypes: locationTypes.map((x) => x["location_type"]),
        sources: sources.map((x) => x["source_index"]),
        collectionAreas: collectionAreas.map((x) => x["dw_collection_area"]),
        startDate: convertUTCDateToLocalDate(
          new Date(filterValues.splitButtonOptions[selectedIndex].start_date)
        ),
        endDate: convertUTCDateToLocalDate(
          new Date(filterValues.splitButtonOptions[selectedIndex].end_date)
        ),
      };
    });
  };

  useEffect(() => {
    if (loadingQuickSetFilterForLocations) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: locations.filter((p) =>
            filterValues.splitButtonOptions[selectedIndex].locations.includes(
              p.location_index
            )
          ),
        };
      });
      setLoadingQuickSetFilterForLocations(false);
    }
  }, [
    loadingQuickSetFilterForLocations,
    locations,
    userFilters,
    selectedIndex,
    filterValues.splitButtonOptions,
  ]);

  useEffect(() => {
    if (loadingQuickSetFilterForParameters) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: filterValues.splitButtonOptions[selectedIndex].parameters
            .filter((p) => parameters.find((x) => x.parameter_index === p))
            .map((parameter) =>
              getFilterNameByIndex(
                parameter,
                parameters,
                "parameter_abbrev",
                "parameter_index"
              )
            ),
        };
      });
      setLoadingQuickSetFilterForParameters(false);
    }
  }, [
    loadingQuickSetFilterForParameters,
    parameters,
    userFilters,
    selectedIndex,
    filterValues.splitButtonOptions,
  ]);

  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) {
      handleSplitButtonClick();
    } else {
      hasMounted.current = true;
    }
  }, [selectedIndex]); //eslint-disable-line

  useEffect(() => {
    if (
      loadingQuickSetFilterForParameters === false &&
      loadingQuickSetFilterForLocations === false
    ) {
      handleSubmitFilters();
    }
  }, [loadingQuickSetFilterForLocations, loadingQuickSetFilterForParameters]); //eslint-disable-line

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Divider my={6} />

      <DisclaimerText>
        This tool is for internal Denver Water use only. Not all data is
        classified as public information. Please contact Alison Witheridge in
        Watershed Planning before providing data externally.
      </DisclaimerText>

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <QueryAndDownloadMap
                  selectedLocations={filterValues.locations.map(
                    (location) => location.location_index
                  )}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {hasParametersLoaded && hasLocationsLoaded && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "1070px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Filter Groups
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MultiSelect
                              fullWidth
                              data={parameterCategories}
                              valueField="param_category_index"
                              displayField="param_category_desc"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterCategories",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterCategories}
                              name="parameterCategories"
                              label="Parameter Categories"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MultiSelect
                              fullWidth
                              data={threats}
                              valueField="threat_index"
                              displayField="threat_desc"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues("threats", e.target);
                                }
                              }}
                              value={filterValues.threats}
                              name="threats"
                              label="Risks"
                              variant="outlined"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>*/}
                      {/*  <MultiSelect*/}
                      {/*    fullWidth*/}
                      {/*    data={priorityTypes}*/}
                      {/*    valueField="priority_type_index"*/}
                      {/*    displayField="priority_type_desc"*/}
                      {/*    onChange={(e) => {*/}
                      {/*      if (e.target.value.includes("all/none")) {*/}
                      {/*        return null;*/}
                      {/*      } else {*/}
                      {/*        handleFilterValues("priorityTypes", e.target);*/}
                      {/*      }*/}
                      {/*    }}*/}
                      {/*    value={filterValues.priorityTypes}*/}
                      {/*    name="priorityTypes"*/}
                      {/*    label="Values"*/}
                      {/*    variant="outlined"*/}
                      {/*    outlineColor="primary"*/}
                      {/*    labelColor="primary"*/}
                      {/*    margin="normal"*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  onFilterSelectAll(
                                    "parameters",
                                    parameters,
                                    "parameter_abbrev"
                                  )
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={2}>
                          {parameters.length === 0 && (
                            <Typography
                              mt={3}
                              align="center"
                              variant="subtitle2"
                            >
                              ----- None Available -----
                            </Typography>
                          )}
                          <Chips
                            data={parameters}
                            valueField="parameter_abbrev"
                            displayField="parameter_abbrev"
                            handleChipClick={(e) =>
                              handleFilterValues(
                                "parameters",
                                e.target.textContent
                              )
                            }
                            type="parameter-type"
                            activeChips={filterValues.parameters}
                            tooltipField1="parameter_name"
                            tooltipField2="units_desc"
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Location Filter Groups
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            style={{ marginLeft: "2px" }}
                            value="end"
                            control={
                              <Switch
                                checked={filterValues.isAssessmentPoint}
                                onChange={() =>
                                  handleFilterValues(
                                    "isAssessmentPoint",
                                    !filterValues.isAssessmentPoint
                                  )
                                }
                                color="primary"
                                name="checkedB"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="Is Assessment Point"
                            labelPlacement="end"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            style={{ marginLeft: "2px" }}
                            value="end"
                            control={
                              <Switch
                                checked={filterValues.isTreatmentPlantSource}
                                onChange={() =>
                                  handleFilterValues(
                                    "isTreatmentPlantSource",
                                    !filterValues.isTreatmentPlantSource
                                  )
                                }
                                color="primary"
                                name="checkedB"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="Is Treatment Source"
                            labelPlacement="end"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={basins}
                            valueField="basin_ndx"
                            displayField="basin"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("basins", e.target);
                              }
                            }}
                            value={filterValues.basins}
                            name="basins"
                            label="Basins"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={locationTypes}
                            valueField="location_type"
                            displayField="location_type"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("locationTypes", e.target);
                              }
                            }}
                            value={filterValues.locationTypes}
                            name="locationTypes"
                            label="Location Types"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={sources}
                            valueField="source_index"
                            displayField="source_desc"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("sources", e.target);
                              }
                            }}
                            value={filterValues.sources}
                            name="sources"
                            label="Sources"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={collectionAreas}
                            valueField="dw_collection_area"
                            displayField="dw_collection_area"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("collectionAreas", e.target);
                              }
                            }}
                            value={filterValues.collectionAreas}
                            name="collectionAreas"
                            label="Collection Areas"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                          />
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Locations
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      locations: locations,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("locations")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Autocomplete
                              limitTags={3}
                              multiple
                              disableCloseOnSelect
                              id="locations"
                              name="locations"
                              options={locations}
                              value={filterValues.locations}
                              getOptionLabel={(option) => {
                                return option.location_id;
                              }}
                              filterSelectedOptions
                              onChange={(event, newValue) => {
                                handleFilterValues("locations", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="primary"
                                  variant="outlined"
                                  label="locations"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Time Period Selection
                      </Typography>
                      <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px - 56px)",
                              marginRight: "12px",
                            }}
                          >
                            <KeyboardDatePicker
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="start-date-picker"
                              label="Start Date"
                              value={filterValues.startDate}
                              onChange={(date) =>
                                handleFilterValues("startDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change start date",
                              }}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                            }}
                          >
                            <KeyboardDatePicker
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="end-date-picker"
                              label="End Date"
                              value={filterValues.endDate}
                              onChange={(date) =>
                                handleFilterValues("endDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change end date",
                              }}
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                        <Tooltip title="Quick-set dates to baseline study period">
                          <IconButton
                            style={{ marginLeft: "8px" }}
                            color="primary"
                            aria-label="reset to baseline dates"
                            onClick={() =>
                              setFilterValues((prevState) => {
                                return {
                                  ...prevState,
                                  startDate: convertUTCDateToLocalDate(
                                    new Date("10/01/11")
                                  ),
                                  endDate: convertUTCDateToLocalDate(
                                    new Date("9/30/21")
                                  ),
                                };
                              })
                            }
                          >
                            <RefreshCw />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Typography
                        mt={6}
                        color="primary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Quick Set & Autosave Filters
                      </Typography>

                      {filterValues.splitButtonOptions.length > 0 && (
                        <Box style={{ width: "100%" }}>
                          <SplitButtonQueryAndDownload
                            options={filterValues?.splitButtonOptions}
                            handleExportClick={handleSplitButtonClick}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            disabled={
                              isLoadingCurrentCount ||
                              isExportLoading ||
                              isSubmitLoading
                            }
                          />
                        </Box>
                      )}
                      <UpdateFrequencyPopover />
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Download Records
              </Typography>
            </AccordionSummary>
            <Box ml={3} mr={3} mb={3}>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={9} style={{ display: "flex" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography mr={2}>
                        Record Count for Selected Data:
                      </Typography>
                      <Typography variant="h6" align="left">
                        {isLoadingCurrentCount ? (
                          <Loader />
                        ) : recordCount !== null ? (
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={recordCount}
                          />
                        ) : (
                          "Please fill out the form to see available records"
                        )}
                      </Typography>
                      {recordCount > 1000000 && (
                        <Typography variant="subtitle2" color="error" ml={2}>
                          Maximum records for download is{" "}
                          <strong>1,000,000</strong>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      disabled={
                        !formValid ||
                        isLoadingCurrentCount ||
                        isExportLoading ||
                        isSubmitLoading
                      }
                      style={{ height: "56px" }}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={() => handleSubmitFilters()}
                    >
                      {isSubmitLoading ? (
                        <Loader />
                      ) : (
                        "Save Filters to Your Account"
                      )}
                    </Button>

                    <Button
                      disabled={
                        recordCount > 1000000 ||
                        !formValid ||
                        isLoadingCurrentCount ||
                        isExportLoading ||
                        isSubmitLoading
                      }
                      style={{ height: "56px" }}
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleExportClick}
                    >
                      <Typography
                        variant="h6"
                        style={{ textTransform: "uppercase" }}
                      >
                        {isExportLoading ? <Loader /> : "Download"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(QueryAndDownload);
