import { useState, useMemo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CheckboxGroup from "../CheckboxGroup";
import { useQuery } from "react-query";
import Fuse from "fuse.js";
import axios from "axios";
import ListSelect from "../ListSelect";
import styled from "styled-components/macro";
import useDebounce from "../../../hooks/useDebounce";
import { Typography } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { PARAMETERS_WITH_HOURLY_INTERVAL } from "../../../pages/dataAccess/TimeSeriesComparisons/constants";

const CheckboxGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const SelectedLocationText = styled(Typography)`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.125rem;
`;

const useFetch = (endpoint) => {
  const { isLoading, error, data } = useQuery(
    [`filter-parameters-${endpoint}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, error, data };
};

const ParametersFilter = ({
  defaultValue,
  defaultFilterValues,
  isMultiSelect = true,
  onSelect,
  title = "Parameters",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    parameterCategories: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedParameters, setSelectedParameters] = useState([]);

  const { data: parameterCategories } = useFetch(
    "dropdown-parameter-categories"
  );
  const { data: parameters } = useFetch("dropdown-parameters");

  const filteredParameters = useMemo(() => {
    if (parameters?.length > 0) {
      const isHourly = defaultFilterValues?.timeInterval === "hourly";

      // apply the parameter category and time interval filters if present
      return parameters.filter((parameter) => {
        const matchesCategory =
          filterValues.parameterCategories.length === 0 ||
          filterValues.parameterCategories.includes(
            parameter.param_category_index
          );
        const matchesTimeInterval = isHourly
          ? PARAMETERS_WITH_HOURLY_INTERVAL.includes(parameter.parameter_index)
          : true;

        return matchesCategory && matchesTimeInterval;
      });
    }

    return [];
  }, [filterValues, parameters, defaultFilterValues]);

  useEffect(() => {
    if (defaultValue?.length > 0 && filteredParameters?.length > 0) {
      const matchingParameters = filteredParameters.filter((parameter) =>
        defaultValue.includes(parameter.parameter_index)
      );
      setSelectedParameters(matchingParameters);
    } else {
      setSelectedParameters([]);
    }
  }, [filteredParameters, defaultValue]);

  const fuzzySearcher = useMemo(() => {
    if (filteredParameters) {
      return new Fuse(filteredParameters, {
        ignoreLocation: true,
        keys: ["parameter_desc", "parameter_abbrev", "parameter_category"],
      });
    }
  }, [filteredParameters]);

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    if (results?.length === 0) {
      setSearchResults(filteredParameters);
      return;
    }

    setSearchResults(results?.map((result) => result.item));
  }, [debouncedSearchValue, fuzzySearcher, filteredParameters]);

  const handleSelectAll = (name) => {
    const options = parameterCategories?.map((x) => x.param_category_index);
    setFilterValues((prev) => ({
      ...prev,
      [name]: options,
    }));
  };

  const handleSelectNone = (name) => {
    setFilterValues((prev) => ({
      ...prev,
      [name]: [],
    }));
  };

  const handleFilterChange = (event) => {
    const { checked, name, value, type } = event.target;

    if (type === "checkbox") {
      const parsedName = event.target.name?.split("--")[0];
      const parsedValue = event.target.name?.split("--")[1];

      const finalValue = isNaN(parsedValue)
        ? parsedValue
        : parseInt(parsedValue);
      if (checked) {
        setFilterValues((prev) => ({
          ...prev,
          [parsedName]: [...prev[parsedName], finalValue],
        }));
      } else {
        setFilterValues((prev) => ({
          ...prev,
          [parsedName]: prev[parsedName].filter((x) => x !== finalValue),
        }));
      }
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSelectParameter = (parameter) => {
    if (!isMultiSelect) {
      setSelectedParameters([parameter]);
      return;
    }
    setSelectedParameters((prev) => {
      if (prev.some((x) => x.parameter_index === parameter.parameter_index)) {
        return prev.filter(
          (x) => x.parameter_index !== parameter.parameter_index
        );
      } else {
        return [...prev, parameter];
      }
    });
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSelect(selectedParameters);
  };

  return (
    <>
      <div style={{ width: 300 }}>
        <Typography
          style={{ fontWeight: 600, fontSize: "1rem" }}
          color={"textSecondary"}
          gutterBottom
        >
          {title}
        </Typography>
        {selectedParameters?.map((parameter) => (
          <SelectedLocationText
            key={parameter.parameter_index}
            variant={"body2"}
            title={parameter.parameter_desc}
          >
            {parameter.parameter_desc}
          </SelectedLocationText>
        ))}
        {selectedParameters?.length === 0 && (
          <Typography variant={"body2"}>No parameters selected</Typography>
        )}
        <Button
          color={"primary"}
          onClick={() => setIsOpen(true)}
          startIcon={
            selectedParameters?.length === 0 ? <AddIcon /> : <EditIcon />
          }
        >
          {selectedParameters?.length === 0
            ? "Add Selections"
            : "Edit Selections"}
        </Button>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Parameters Filter</DialogTitle>
        <DialogContent>
          <FiltersContainer>
            <div>
              <CheckboxGroups>
                <CheckboxGroup
                  label={"Parameter Categories"}
                  name={"parameterCategories"}
                  onChange={handleFilterChange}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={parameterCategories}
                  valueField={"param_category_index"}
                  displayField={"parameter_category"}
                  value={filterValues.parameterCategories}
                />
              </CheckboxGroups>
            </div>
            <ListSelect
              isMultiSelect={isMultiSelect}
              options={searchResults}
              displayField={"parameter_desc"}
              subtitleFields={["parameter_category"]}
              valueField={"parameter_index"}
              onSelect={handleSelectParameter}
              onSelectAll={() => setSelectedParameters(filteredParameters)}
              onSelectNone={() => setSelectedParameters([])}
              onSearch={(e) => setSearchValue(e.target.value)}
              value={selectedParameters}
              searchValue={searchValue}
            />
          </FiltersContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button
            variant={"contained"}
            color={"primary"}
            disabled={selectedParameters?.length === 0}
            onClick={handleSubmit}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ParametersFilter;
