import { useState, useMemo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CheckboxGroup from "../CheckboxGroup";
import { useQuery } from "react-query";
import Fuse from "fuse.js";
import axios from "axios";
import ListSelect from "../ListSelect";
import styled from "styled-components/macro";
import LocationsFilterMap from "./Map";
import useDebounce from "../../../hooks/useDebounce";
import { Typography } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { doDateRangesOverlap } from "../../../utils";

const CheckboxGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
  margin-top: 1rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const MapContainer = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
`;

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const SelectedLocationText = styled(Typography)`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.125rem;
`;

const useFetch = (endpoint) => {
  const { isLoading, error, data } = useQuery(
    [`filter-locations-${endpoint}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, error, data };
};

const LocationsFilter = ({
  defaultValue,
  defaultFilterValues,
  isMultiSelect = true,
  onSelect,
  title = "Locations",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [filterValues, setFilterValues] = useState({
    basins: [],
    locationTypes: [],
    sources: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const { data: locationTypes } = useFetch("dropdown-loc-types");

  // todo maybe exclude source index 3 and 4 like in TS compare
  // ["DW Non-Stream", "Stream Station"]
  const { data: sources } = useFetch("dropdown-sources");
  const { data: basins } = useFetch("dropdown-basins");
  const { data: locations } = useFetch("dropdown-locations-assoc");

  const filteredLocations = useMemo(() => {
    if (locations?.length > 0) {
      const parameters = defaultFilterValues?.parameters || [];
      const startDate = defaultFilterValues?.startDate;
      const endDate = defaultFilterValues?.endDate;

      return locations.filter((location) => {
        const matchesParameters =
          parameters.length === 0 ||
          parameters.some((parameter) =>
            location.parameter_index_array.includes(parameter)
          );
        const matchesBasin =
          filterValues.basins.length === 0 ||
          filterValues.basins.includes(location.basin_ndx);

        const matchesSource =
          filterValues.sources.length === 0 ||
          filterValues.sources.some((x) =>
            location.source_index_array.includes(x)
          );

        const matchesLocationType =
          filterValues.locationTypes.length === 0 ||
          filterValues.locationTypes.includes(location.loc_type);

        const matchesDateRange = doDateRangesOverlap(
          new Date(location.por_start),
          new Date(location.por_end),
          startDate,
          endDate
        );

        return (
          matchesDateRange &&
          matchesParameters &&
          matchesBasin &&
          matchesSource &&
          matchesLocationType
        );
      });
    }

    return [];
  }, [filterValues, locations, defaultFilterValues]);

  useEffect(() => {
    if (defaultValue?.length > 0 && filteredLocations?.length > 0) {
      const matchingLocations = filteredLocations.filter((location) =>
        defaultValue.includes(location.location_index)
      );
      setSelectedLocations(matchingLocations);
    } else {
      setSelectedLocations([]);
    }
  }, [filteredLocations, defaultValue]);

  const fuzzySearcher = useMemo(() => {
    if (filteredLocations) {
      return new Fuse(filteredLocations, {
        ignoreLocation: true,
        keys: [
          "location_name",
          "location_id",
          "location_title",
          "basin",
          "loc_type",
          "source_abbrevs",
        ],
      });
    }
  }, [filteredLocations]);

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    if (results?.length === 0) {
      setSearchResults(filteredLocations);
      return;
    }

    setSearchResults(results?.map((result) => result.item));
  }, [debouncedSearchValue, fuzzySearcher, filteredLocations]);

  const handleSelectAll = (name) => {
    let options = [];
    if (name === "locationTypes") {
      options = locationTypes?.map((x) => x.loc_type);
    } else if (name === "sources") {
      options = sources?.map((x) => x.source_index);
    } else if (name === "basins") {
      options = basins?.map((x) => x.basin_ndx);
    }

    setFilterValues((prev) => ({
      ...prev,
      [name]: options,
    }));
  };

  const handleSelectNone = (name) => {
    setFilterValues((prev) => ({
      ...prev,
      [name]: [],
    }));
  };

  const handleFilterChange = (event) => {
    const { checked, name, value, type } = event.target;

    if (type === "checkbox") {
      const parsedName = event.target.name?.split("--")[0];
      const parsedValue = event.target.name?.split("--")[1];

      const finalValue = isNaN(parsedValue)
        ? parsedValue
        : parseInt(parsedValue);
      if (checked) {
        setFilterValues((prev) => ({
          ...prev,
          [parsedName]: [...prev[parsedName], finalValue],
        }));
      } else {
        setFilterValues((prev) => ({
          ...prev,
          [parsedName]: prev[parsedName].filter((x) => x !== finalValue),
        }));
      }
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSelectLocation = (location) => {
    if (!isMultiSelect) {
      setSelectedLocations([location]);
      return;
    }
    setSelectedLocations((prev) => {
      if (prev.some((x) => x.location_index === location.location_index)) {
        return prev.filter((x) => x.location_index !== location.location_index);
      } else {
        return [...prev, location];
      }
    });
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSelect(selectedLocations);
  };

  return (
    <>
      <div style={{ width: 300 }}>
        <Typography
          style={{ fontWeight: 600, fontSize: "1rem" }}
          color={"textSecondary"}
          gutterBottom
        >
          {title}
        </Typography>
        {selectedLocations?.map((location) => (
          <SelectedLocationText
            key={location.location_index}
            variant={"body2"}
            title={location.location_title}
          >
            {location.location_title}
          </SelectedLocationText>
        ))}
        {selectedLocations?.length === 0 && (
          <Typography variant={"body2"}>No locations selected</Typography>
        )}
        <Button
          color={"primary"}
          onClick={() => setIsOpen(true)}
          startIcon={
            selectedLocations?.length === 0 ? <AddIcon /> : <EditIcon />
          }
        >
          {selectedLocations?.length === 0
            ? "Add Selections"
            : "Edit Selections"}
        </Button>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Locations Filter</DialogTitle>
        <DialogContent>
          {isMapVisible && (
            <MapContainer>
              <LocationsFilterMap
                locations={searchResults}
                selectedLocations={selectedLocations}
              />
            </MapContainer>
          )}

          <FiltersContainer>
            <div>
              <Button
                color={"primary"}
                variant={"outlined"}
                onClick={() => setIsMapVisible((s) => !s)}
              >
                {isMapVisible ? "Hide Map" : "Show Locations Map"}
              </Button>
              <CheckboxGroups>
                <CheckboxGroup
                  label={"Location Types"}
                  name={"locationTypes"}
                  onChange={handleFilterChange}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={locationTypes}
                  valueField={"loc_type"}
                  displayField={"loc_type"}
                  value={filterValues.locationTypes}
                />
                <CheckboxGroup
                  label={"Sources"}
                  name={"sources"}
                  onChange={handleFilterChange}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={sources}
                  valueField={"source_index"}
                  displayField={"source"}
                  value={filterValues.sources}
                />
                <CheckboxGroup
                  label={"Basins"}
                  name={"basins"}
                  onChange={handleFilterChange}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={basins}
                  valueField={"basin_ndx"}
                  displayField={"basin"}
                  value={filterValues.basins}
                />
              </CheckboxGroups>
            </div>
            <ListSelect
              isMultiSelect={isMultiSelect}
              options={searchResults}
              displayField={"location_title"}
              subtitleFields={["basin", "loc_type", "source_abbrevs"]}
              valueField={"location_index"}
              onSelect={handleSelectLocation}
              onSelectAll={() => setSelectedLocations(filteredLocations)}
              onSelectNone={() => setSelectedLocations([])}
              onSearch={(e) => setSearchValue(e.target.value)}
              value={selectedLocations}
              searchValue={searchValue}
            />
          </FiltersContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button
            variant={"contained"}
            color={"primary"}
            disabled={selectedLocations?.length === 0}
            onClick={handleSubmit}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationsFilter;
