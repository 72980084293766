import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { downloadCsv, groupByValueArray } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Icon,
  isWidthUp,
  MenuItem,
  TextField,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import WaterQualityReportCardMap from "../../../components/map/WaterQualityReportCardMap";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import Chips from "../../../components/Chips";
import { withStyles } from "@material-ui/core/styles";
import Panel from "../../../components/panels/Panel";
import Table from "../../../components/Table";
import WaterQualityReportCardDates from "./WaterQualityReportCardDates";
import DownloadIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import { useReactToPrint } from "react-to-print";
import UpdateFrequencyPopover from "../../../components/map/components/UpdateFrequencyPopover";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);

const MapContainer = styled.div`
  height: 646px;
  width: 100%;
`;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2196F3",
      },
    },
  },
})(TextField);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

const WaterQualityReportCard = ({ width }) => {
  const saveRef = useRef(null);
  const initFilterValues = {
    analysis: "median",
    season: "0",
    collectionSystem: "South",
    parameterCategories: [],
    threats: [],
    priorityTypes: [],
    parameters: [],
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const benchmarkScore =
    "s" + filterValues.season + "_benchmark_scale_" + filterValues.analysis;

  const riskScore =
    "s" + filterValues.season + "_risk_" + filterValues.analysis;

  const dynamicTrend =
    (filterValues.season === "0" ? "" : "s" + filterValues.season + "_") +
    "trend_dynamic";

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  const getFilterIndexByName = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterField] === name);
    return filter[filterIndex];
  };

  const cleanFilter = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.indexOf(x[filterField]) !== -1) {
        newParams.push(x[filterField]);
      }
    });
    return newParams;
  };

  const collectionSystems = [
    {
      value: "North",
      label: "North",
    },
    {
      value: "South",
      label: "South",
    },
  ];

  const analysisTypes = [
    {
      value: "median",
      label: "Median",
    },
    {
      value: "pctile85",
      label: "85th Percentile",
    },
  ];

  const seasons = [
    {
      value: "0",
      label: "Annual",
    },
    {
      value: "1",
      label: "Low Flow",
    },
    {
      value: "2",
      label: "Shoulder: Low to High",
    },
    {
      value: "3",
      label: "High Flow",
    },
    {
      value: "4",
      label: "Shoulder: High to Low",
    },
  ];

  const [parameterCategories] = useFetchData(
    "list-parameter-categories-graph-mode",
    [],
    false
  );

  const [priorityTypes] = useFetchData(
    "list-priority-types-graph-mode",
    [],
    false
  );

  const [threats] = useFetchData("list-threats-graph-mode", [], false);

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (parameterCategories.length && priorityTypes.length && threats.length) {
      onFilterSelectAll(
        "parameterCategories",
        parameterCategories,
        "param_category_desc"
      );
      onFilterSelectAll("priorityTypes", priorityTypes, "priority_type_desc");
      onFilterSelectAll("threats", threats, "threat_desc");
      setHasFiltersLoaded(true);
    }
  }, [parameterCategories, priorityTypes, threats]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    [
      "list-parameters-graph-mode",
      filterValues.parameterCategories,
      filterValues.priorityTypes,
      filterValues.threats,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-parameters-graph-mode`,
            {
              parameterCategories: filterValues.parameterCategories.map(
                (parameterCategory) =>
                  getFilterIndexByName(
                    parameterCategory,
                    parameterCategories,
                    "param_category_desc",
                    "param_category_index"
                  )
              ),
              priorityTypes: filterValues.priorityTypes.map((priorityType) =>
                getFilterIndexByName(
                  priorityType,
                  priorityTypes,
                  "priority_type_desc",
                  "priority_type_index"
                )
              ),
              threats: filterValues.threats.map((threat) =>
                getFilterIndexByName(
                  threat,
                  threats,
                  "threat_desc",
                  "threat_index"
                )
              ),
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (parameters?.length && !hasParametersLoaded) {
      setHasParametersLoaded(true);
      onFilterSelectAll("parameters", parameters, "parameter_abbrev");
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: cleanFilter(
            filterValues.parameters,
            parameters,
            "parameter_abbrev"
          ),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  const handleFilterValues = (name, value) => {
    if (
      ![
        "analysis",
        "season",
        "collectionSystem",
        "parameterCategories",
        "priorityTypes",
        "threats",
      ].includes(name)
    ) {
      setFilterValues((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(value);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else if (
      ["parameterCategories", "priorityTypes", "threats"].includes(name)
    ) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const cellColorBackground = (value) => {
    if (value === null) {
      return "black";
    } else if (value === 3) {
      return "red";
    } else if (value === 2) {
      return "yellow";
    } else if (value === 1) {
      return "green";
    } else return "purple";
  };

  const cellColor = (value) => {
    if (value === 2) {
      return "black";
    } else return "white";
  };

  const setTrendIcon = (trend) => {
    const val = trend ? trend.toLowerCase() : "";
    if (val === "no trend") {
      return `swap_vert`;
    } else if (val.includes(`increasing`)) {
      return `arrow_upward`;
    } else if (val.includes(`decreasing`)) {
      return `arrow_downward`;
    } else if (val === "stable") {
      return `trending_flat`;
    } else {
      return "not_interested";
    }
  };

  const [columns, setColumns] = useState([]);
  const { data, isFetching, error, refetch } = useQuery(
    [
      "locations-map-risk-mode",
      hasParametersLoaded,
      filterValues.analysis,
      filterValues.season,
      filterValues.parameters,
      filterValues.collectionSystem,
    ],
    async () => {
      if (hasParametersLoaded) {
        setColumns([]);
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/locations-map-risk-mode`,
            {
              parameters: filterValues.parameters.map((parameter) =>
                getFilterIndexByName(
                  parameter,
                  parameters,
                  "parameter_abbrev",
                  "parameter_index"
                )
              ),
              collectionSystem: filterValues.collectionSystem,
            }
          );

          const groupedData = groupByValueArray(data, "parameter");
          const buildColumns = [
            {
              title: "Parameters",
              field: "parameter",
              cellStyle: {
                whiteSpace: "nowrap",
                padding: "3px 3px 3px 3px",
                position: "sticky",
                left: 0,
                background: "white",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              headerStyle: {
                padding: "3px 3px 3px 3px",
                fontWeight: 900,
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 11,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              position: "sticky",
              left: 0,
              background: "white",
              defaultSort: "asc",
            },
            {
              title: "Parameter Categories",
              field: "parameterGroup",
              cellStyle: {
                whiteSpace: "nowrap",
                padding: "3px 3px 3px 3px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              headerStyle: {
                padding: "3px 3px 3px 3px",
                fontWeight: 900,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            },
          ];

          const crossTabbedData = groupedData.map((parameter) => {
            const obj = {};
            obj["parameterGroup"] = getFilterIndexByName(
              parameter[0].parameter,
              parameters,
              "parameter_name",
              "param_category_desc"
            );
            obj["parameter"] = parameter[0].parameter;
            parameter.forEach((item) => {
              if (item?.location_name) {
                obj[item.location_name] = {
                  score: item[benchmarkScore],
                  risk: item[riskScore],
                  trend: item[dynamicTrend],
                };
                if (
                  !buildColumns.find(
                    (col) =>
                      col.title ===
                      `${item.location_abbrev} (${item.location_id})`
                  )
                ) {
                  buildColumns.push({
                    title: `${item.location_abbrev} (${item.location_id})`,
                    field: item.location_name,
                    sort: item.abasin_order,
                    sorting: false,
                    filtering: false,
                    render: (rowData) => {
                      return (
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: 600 }}>
                            {rowData[item.location_name]?.score ?? null}
                          </Typography>
                          <Icon style={{ marginLeft: "5px" }}>
                            {setTrendIcon(
                              rowData[item.location_name]?.trend ?? null
                            )}
                          </Icon>
                        </span>
                      );
                    },

                    cellStyle: (e, rowData) => {
                      return {
                        padding: "5px",
                        backgroundColor: cellColorBackground(
                          rowData[item.location_name]?.risk ?? null
                        ),
                        color: cellColor(
                          rowData[item.location_name]?.risk ?? null
                        ),
                        textAlign: "center",
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      };
                    },
                    headerStyle: {
                      padding: "3px",
                      textAlign: "center",
                      fontWeight: 900,
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  });
                }
              }
            });
            return obj;
          });
          setColumns(
            buildColumns.sort((a, b) => {
              return a.sort - b.sort;
            })
          );
          return crossTabbedData;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handlePrintClick = useReactToPrint({
    content: () => saveRef.current,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Time Series Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Quality Report Card
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <WaterQualityReportCardMap />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {hasParametersLoaded && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "646px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <UpdateFrequencyPopover />
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Filter Groups
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              fullWidth
                              data={parameterCategories}
                              valueField="param_category_desc"
                              displayField="param_category_desc"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterCategories",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterCategories}
                              name="parameterCategories"
                              label="Parameter Categories"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                            <MultiSelect
                              fullWidth
                              data={priorityTypes}
                              valueField="priority_type_desc"
                              displayField="priority_type_desc"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues("priorityTypes", e.target);
                                }
                              }}
                              value={filterValues.priorityTypes}
                              name="priorityTypes"
                              label="Values"
                              variant="outlined"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={8} xl={6}>
                            <MultiSelect
                              fullWidth
                              data={threats}
                              valueField="threat_desc"
                              displayField="threat_desc"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues("threats", e.target);
                                }
                              }}
                              value={filterValues.threats}
                              name="threats"
                              label="Risks"
                              variant="outlined"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  onFilterSelectAll(
                                    "parameters",
                                    parameters,
                                    "parameter_abbrev"
                                  )
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        {parameters.length === 0 && (
                          <Typography mt={3} align="center" variant="subtitle2">
                            ----- None Available -----
                          </Typography>
                        )}
                        <Chips
                          data={parameters}
                          valueField="parameter_abbrev"
                          displayField="parameter_abbrev"
                          handleChipClick={(e) =>
                            handleFilterValues(
                              "parameters",
                              e.target.textContent
                            )
                          }
                          type="parameter-type"
                          activeChips={filterValues.parameters}
                          tooltipField1="parameter_name"
                          tooltipField2="units_desc"
                        />
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Statistics Toggles
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            select
                            color="primary"
                            // size="small"
                            label="Statistic"
                            value={filterValues.analysis}
                            onChange={(e) =>
                              handleFilterValues("analysis", e.target.value)
                            }
                          >
                            {analysisTypes.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            select
                            color="primary"
                            // size="small"
                            label="Collection System"
                            value={filterValues.collectionSystem}
                            onChange={(e) =>
                              handleFilterValues(
                                "collectionSystem",
                                e.target.value
                              )
                            }
                          >
                            {collectionSystems.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            select
                            // size="small"
                            label="Flow Regime"
                            value={filterValues.season}
                            onChange={(e) =>
                              handleFilterValues("season", e.target.value)
                            }
                          >
                            {seasons.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Time Period Selection
                      </Typography>
                      <WaterQualityReportCardDates onSubmit={refetch} />
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded ref={saveRef}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Water Quality Report Card
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    filtering={true}
                    isLoading={isFetching}
                    pageSize={60}
                    label="Data Points Hydrologic Health"
                    columns={columns}
                    data={data}
                    height="100%"
                    sortArrow={<React.Fragment />}
                    exportButton={false}
                    copyIcon={false}
                    actions={[
                      {
                        icon: DownloadIcon,
                        tooltip: "Export as CSV",
                        isFreeAction: true,
                        onClick: () => {
                          const headerRow = columns.map((col) => {
                            return col.title;
                          });
                          const dataRows = data.map(({ tableData, ...row }) => {
                            return columns.map((col) => {
                              if (typeof row[col.field] !== "object") {
                                return `"${row[col.field] ?? ""}"`;
                              } else {
                                return JSON.stringify(row[col.field])
                                  .replaceAll(",", "; ")
                                  .replace(/["{}]/g, "");
                              }
                            });
                          });

                          const csvContent = [headerRow, ...dataRows]
                            .map((e) => e.join(","))
                            .join("\n");
                          const csvFileName = "Water Quality Report Card";

                          downloadCsv(csvContent, csvFileName);
                        },
                      },
                      {
                        icon: SaveIcon,
                        tooltip: "Save or Print as PDF",
                        isFreeAction: true,
                        onClick: () => {
                          handlePrintClick();
                        },
                      },
                    ]}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(WaterQualityReportCard);
