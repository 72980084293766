import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import UpdateFrequencyPopover from "../../../components/map/components/UpdateFrequencyPopover";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 48px - 48px - 64px);
  position: relative;
  margin-top: 10px;
`;

const ReservoirPortalTool = () => {
  return (
    <>
      <Helmet title="Reservoir Portal Tool" />
      <UpdateFrequencyPopover />
      <Container>
        <embed
          src="https://lrewater.shinyapps.io/Denver_Water_Reservoir_Water_Quality_Visualization_Tool/"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          title="Reservoir Portal Tool"
        />
      </Container>
    </>
  );
};

export default ReservoirPortalTool;
