import React, { useRef } from "react";

import { Helmet } from "react-helmet-async";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Divider as MuiDivider,
  FormControl,
  FormLabel,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { useParameterComparisonScatterPlot } from "./useParameterComparisonScatterPlot";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Table from "../../../components/Table";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import LocationsFilter from "../../../components/filters/LocationsFilter";
import ParametersFilter from "../../../components/filters/ParametersFilter";
import ScatterPlotChart from "../../../components/graphs/ScatterPlotChart";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Accordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AxisFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const ScatterPlotContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

function isSubmitDisabled(filterValues) {
  const { locationsX, locationsY, parameterY, parameterX, startDate, endDate } =
    filterValues;

  const hasLocations = locationsX?.length && locationsY?.length;
  const hasTooManyLocations = locationsX?.length + locationsY?.length > 20;
  const hasParameters = parameterY?.length > 0 || parameterX?.length > 0;
  const hasStartDate = !!startDate;
  const hasEndDate = !!endDate;

  return (
    !hasLocations ||
    !hasParameters ||
    hasTooManyLocations ||
    !hasStartDate ||
    !hasEndDate
  );
}

function ParameterComparisonScatterPlot() {
  const saveRef = useRef(null);
  const { data, tableColumns, graphData, filterValues, handleFilterChange } =
    useParameterComparisonScatterPlot();

  const handleSelectXLocations = (locations) => {
    handleFilterChange(
      "locationsX",
      locations?.map((l) => l.location_index)
    );
  };

  const handleSelectYLocations = (locations) => {
    handleFilterChange(
      "locationsY",
      locations?.map((l) => l.location_index)
    );
  };

  const handleSelectYParameter = (parameter) => {
    handleFilterChange(
      "parameterY",
      parameter?.map((p) => p.parameter_index)
    );
  };

  const handleSelectXParameter = (parameter) => {
    handleFilterChange(
      "parameterX",
      parameter?.map((p) => p.parameter_index)
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Parameter Comparison Scatter Plot" />
      <Typography variant="h3" gutterBottom display="inline">
        Parameter Comparison Scatter Plot
      </Typography>
      <Divider my={6} />
      <Typography variant="h4" gutterBottom>
        Getting Started
      </Typography>
      <Typography mb={4}>
        Use this tool to make comparisons for two parameter across multiple
        comparisons
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="scatter-plot"
              id="scatter-plot"
            >
              <Typography variant="h4" ml={10}>
                Time Period Filters
              </Typography>
            </AccordionSummary>
            <Box mx={2}>
              <AccordionDetails>
                <FilterContainer>
                  <FilterRow>
                    <FormControl
                      component="fieldset"
                      style={{ marginRight: "10px" }}
                    >
                      <FormLabel component="legend">Start Date</FormLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={filterValues.startDate}
                          onChange={(newValue) =>
                            handleFilterChange("startDate", newValue)
                          }
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          inputVariant="outlined"
                          size="small"
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">End Date</FormLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={filterValues.endDate}
                          onChange={(newValue) =>
                            handleFilterChange("endDate", newValue)
                          }
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          inputVariant="outlined"
                          size="small"
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </FilterRow>
                </FilterContainer>

                <FilterContainer>
                  <Typography variant="h4" mb={4}>
                    X Axis Filters
                  </Typography>
                  <AxisFilterRow>
                    <ParametersFilter
                      defaultValue={filterValues?.parameterX}
                      onSelect={handleSelectXParameter}
                      isMultiSelect={false}
                      title="Parameter"
                    />
                    <LocationsFilter
                      onSelect={handleSelectXLocations}
                      defaultFilterValues={{
                        parameters: filterValues?.parameterX,
                        startDate: filterValues?.startDate,
                        endDate: filterValues?.endDate,
                      }}
                      defaultValue={filterValues?.locationsX}
                      isMultiSelect={false}
                    />
                  </AxisFilterRow>
                </FilterContainer>

                <FilterContainer>
                  <Typography variant="h4" mb={4}>
                    Y Axis Filters
                  </Typography>
                  <AxisFilterRow>
                    <ParametersFilter
                      defaultValue={filterValues?.parameterY}
                      onSelect={handleSelectYParameter}
                      isMultiSelect={false}
                      title="Parameter"
                    />
                    <LocationsFilter
                      onSelect={handleSelectYLocations}
                      defaultFilterValues={{
                        parameters: filterValues?.parameterY,
                        startDate: filterValues?.startDate,
                        endDate: filterValues?.endDate,
                      }}
                      defaultValue={filterValues?.locationsY}
                    />
                  </AxisFilterRow>
                </FilterContainer>

                <SubmitGrid item container>
                  <Grid item style={{ width: "calc(100% - 162px)" }}>
                    {!data?.graph?.data && (
                      <Alert severity="info">
                        After selecting your scatter plot inputs, click the
                        'Submit' button to load an interactive scatter plot for
                        comparison across different locations and parameters.
                      </Alert>
                    )}
                    {(data?.graph?.data?.yAxis?.length === 0 ||
                      data?.graph?.data?.xAxis?.length === 0) && (
                      <Alert severity="warning">
                        No data available for{" "}
                        {data?.graph?.data?.yAxis?.length === 0 &&
                        data?.graph?.data?.xAxis?.length === 0
                          ? "Y or X"
                          : data?.graph?.data?.yAxis?.length === 0
                          ? "Y"
                          : "X"}{" "}
                        axis
                      </Alert>
                    )}
                  </Grid>
                  <Grid item>
                    <SaveGraphButton
                      ref={saveRef}
                      title="Timeseries Comparison Graph"
                      disabled={data.graph.isLoading || !data.graph.data}
                    />
                    <Button
                      onClick={() => data.graph.refetch()}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large"
                      style={{ marginLeft: "10px" }}
                      disabled={isSubmitDisabled(filterValues)}
                    >
                      Submit
                    </Button>
                  </Grid>
                </SubmitGrid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      </Grid>

      {!data.graph.isLoading && (
        <>
          {data.graph.data && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Scatter Plot
                </Typography>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <ScatterPlotContainer>
                    <TableWrapper>
                      <ScatterPlotChart
                        data={graphData}
                        error={data.graph.error}
                        isLoading={data.graph.isLoading}
                        yLLabel={graphData?.yLLabel}
                        xLabel={graphData?.xLabel}
                        ref={saveRef}
                      />
                    </TableWrapper>
                  </ScatterPlotContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
          {data.graph.data && !data.graph.isLoading && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  Table
                </Typography>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <TableWrapper>
                    <Table
                      label="Parameter Comparison Table"
                      columns={tableColumns}
                      data={[
                        ...[].concat.apply([], data?.graph?.data?.leftAxis),
                      ]}
                      height="590px"
                    />
                  </TableWrapper>
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default ParameterComparisonScatterPlot;
