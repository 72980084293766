import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  FormControl,
  Grid as MuiGrid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Loader from "../../../components/Loader";
import SubmitIcon from "@material-ui/icons/Publish";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import InfoIcon from "@material-ui/icons/Info";
import useFetchData from "../../../hooks/useFetchData";
import axios from "axios";
import { useApp } from "../../../AppProvider";
import { convertUTCDateToLocalDate } from "../../../utils";
import { RefreshCw } from "react-feather";

const Grid = styled(MuiGrid)(spacing);

const WaterQualityReportCardDates = ({ onSubmit }) => {
  const { doToast } = useApp();
  const initFilterValues = {
    startDate: null,
    endDate: null,
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const [startDate] = useFetchData(`analysis-start-date`, [], false);
  const [endDate] = useFetchData(`analysis-end-date`, [], false);

  useEffect(() => {
    if (startDate.length > 0 && endDate.length > 0) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          startDate: convertUTCDateToLocalDate(
            new Date(startDate[0].analysis_start_date)
          ),
          endDate: convertUTCDateToLocalDate(
            new Date(endDate[0].analysis_end_date)
          ),
        };
      });
    }
  }, [startDate, endDate]);

  const handleFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const SubmitTooltip = ({ children, text }) => {
    return (
      <Tooltip
        placement="bottom"
        title={
          <Box display="flex" justifyContent="center" alignItems="center">
            <InfoIcon color="primary" />
            <Typography
              component="span"
              variant="h6"
              style={{ textAlign: "center" }}
            >
              {text}
            </Typography>
            <InfoIcon color="primary" />
          </Box>
        }
        arrow
      >
        {children}
      </Tooltip>
    );
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleControlsSubmit = () => {
    async function send() {
      try {
        setIsSubmitLoading(true);
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/analysis-date-trigger`,
          {
            startDate: filterValues.startDate,
            endDate: filterValues.endDate,
          }
        );

        doToast(
          "info",
          "Records are updating. This may take several minutes. Do not submit a query until this process is complete. Data will refresh automatically when the update finishes."
        );
        let count = 0;
        let timeoutHandle = () => {
          let status = "";
          const fetchNewDate = async () => {
            const response = await axios.get(
              `${process.env.REACT_APP_ENDPOINT}/api/dynamic-stats-trigger-status`
            );
            status = response.data;
          };
          fetchNewDate();

          setTimeout(() => {
            if (status.status === "running") {
              //give fail notice if the request takes longer than 3 minutes
              if (count > 36) {
                console.error(
                  "Query taking longer than normal. Timeout invoked."
                );
                setIsSubmitLoading(false);
                doToast(
                  "error",
                  "Query taking longer than normal. Timeout invoked."
                );
                return;
              }
              count++;
              timeoutHandle();
            } else {
              doToast("success", "Records were updated successfully.");
              onSubmit();
              setIsSubmitLoading(false);
            }
          }, 5000);
        };
        timeoutHandle();
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          doToast("error", "Update was canceled.");
          setIsSubmitLoading(false);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong.";
          doToast("error", message);
          setIsSubmitLoading(false);
        }
      }
    }
    send();
  };

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormControl
          style={{
            width: "calc(50% - 36px - 25px)",
            marginRight: "12px",
          }}
        >
          <KeyboardDatePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            disableToolbar
            format="MM/dd/yyyy"
            id="start-date-picker"
            label="Start Date"
            value={filterValues.startDate}
            onChange={(date) => handleFilterValues("startDate", date)}
            InputAdornmentProps={{
              "aria-label": "change start date",
            }}
          />
        </FormControl>
        <FormControl
          style={{
            width: "calc(50% - 36px - 25px)",
            marginRight: "6px",
          }}
        >
          <KeyboardDatePicker
            autoOk
            inputVariant="outlined"
            disableToolbar
            format="MM/dd/yyyy"
            id="end-date-picker"
            label="End Date"
            value={filterValues.endDate}
            onChange={(date) => handleFilterValues("endDate", date)}
            InputAdornmentProps={{
              "aria-label": "change end date",
            }}
          />
        </FormControl>
        <SubmitTooltip text="Click to submit dates to recalculate benchmark statistics">
          <span
            style={{
              marginTop: "3px",
              // display: "flex",
              // alignItems: "center",
            }}
          >
            {isSubmitLoading ? (
              <Loader />
            ) : (
              <IconButton
                color="secondary"
                aria-label="delete"
                onClick={handleControlsSubmit}
              >
                <SubmitIcon />
              </IconButton>
            )}
          </span>
        </SubmitTooltip>
        <Tooltip title="Quick-set dates to baseline study period">
          <IconButton
            style={{ marginLeft: "8px" }}
            color="primary"
            aria-label="reset to baseline dates"
            onClick={() => {
              handleFilterValues(
                "startDate",
                convertUTCDateToLocalDate(new Date("10/01/11"))
              );
              handleFilterValues(
                "endDate",
                convertUTCDateToLocalDate(new Date("9/30/21"))
              );
            }}
          >
            <RefreshCw />
          </IconButton>
        </Tooltip>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
export default WaterQualityReportCardDates;
