import React, { forwardRef } from "react";

import { Chart, Scatter } from "react-chartjs-2";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "../Loader";
import { Typography } from "@material-ui/core";
import { DEFAULT_LINE_COLORS } from "../../utils";

Chart.register(zoomPlugin);

const ScatterPlotChart = forwardRef(
  (
    {
      data,
      error,
      isLoading = true,
      xLabel = null,
      yLLabel = null,
      yLReverse = false,
      reverseLegend = true,
      suggestedMin = null,
    },
    ref
  ) => {
    const plugins = [
      {
        id: "chartFillBackground",
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.fillStyle = DEFAULT_LINE_COLORS.lightGray;
          ctx.fillRect(0, 0, chart.width, chart.height);
        },
      },
      {
        id: "chartAreaBorder",
        beforeDraw(chart) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          if (chart.options.plugins.zoom.zoom.wheel.enabled) {
            ctx.save();
            ctx.strokeStyle = "#800000";
            ctx.lineWidth = 3;
            ctx.strokeRect(left, top, width, height);
            ctx.restore();
          }
        },
      },
      {
        id: "annotatedVerticalLine",
        afterDraw(chart) {
          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.yL;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 9;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.2)";
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
            ctx.stroke();
            ctx.restore();
          }
        },
      },
    ];

    const options = {
      type: "scatter",
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: "nearest",
      },
      plugins: {
        filler: {
          propagate: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItems) {
              return [
                tooltipItems.dataset.label,
                tooltipItems.dataset.date[tooltipItems.dataIndex],
                `X - ${tooltipItems.dataset.parameterX}: ${tooltipItems.parsed.x} ${tooltipItems.dataset.unitsX}`,
                `Y - ${tooltipItems.dataset.parameterY}: ${tooltipItems.parsed.y} ${tooltipItems.dataset.unitsY}`,
              ];
            },
          },
        },
        legend: {
          display: true,
          reverse: reverseLegend,
          labels: {
            usePointStyle: false,
            color: DEFAULT_LINE_COLORS.darkGray,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
          },
          zoom: {
            mode: "xy",
            overScaleMode: "y",
            wheel: {
              enabled: false,
            },
            pinch: {
              enabled: true,
            },
          },
          //TODO line segment styling
        },
      },

      scales: {
        x: {
          title: {
            display: true,
            text: xLabel,
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          type: "linear",
          position: "bottom",
          grid: {
            display: false,
          },
          ticks: {
            color: DEFAULT_LINE_COLORS.darkGray,
            maxTicksLimit: 9,
          },
        },
        yL: {
          reverse: yLReverse,
          suggestedMin: suggestedMin,
          position: "left",
          display: !!yLLabel,
          title: {
            display: true,
            text: yLLabel,
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          ticks: {
            color: DEFAULT_LINE_COLORS.darkGray,
          },
          grid: {
            borderDash: [5, 5],
            drawBorder: true,
            drawTicks: true,
          },
        },
      },
      onClick(e) {
        const chart = e.chart;
        chart.options.plugins.zoom.zoom.wheel.enabled =
          !chart.options.plugins.zoom.zoom.wheel.enabled;
        chart.update();
      },
    };

    if (error) return "An error has occurred: " + error.message;
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.datasets?.length > 0 ? (
              <Scatter
                plugins={plugins}
                ref={ref}
                data={data}
                options={options}
              />
            ) : (
              <Typography>No Data Available</Typography>
            )}
          </>
        )}
      </>
    );
  }
);
export default ScatterPlotChart;
