import axios from "axios";
import { useQuery } from "react-query";
import { useCallback, useState } from "react";
import moment from "moment";
import { lighten } from "@material-ui/core";
import { COLOR_PALETTES } from "../../../utils";

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;

const fetchData = async (endpoint) => {
  try {
    const { data } = await axios.get(`${API_ENDPOINT}/api/${endpoint}`);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const useDataQuery = (key, endpoint) =>
  useQuery(key, () => fetchData(endpoint), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const getChartData = async (locationId, parameterId, startDate, endDate) => {
  const response = await axios.get(
    `${API_ENDPOINT}/api/ts-daily-graph-data/${parameterId}/${locationId}/${moment(
      startDate
    ).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}`
  );
  return response.data;
};

const statistics = [
  {
    label: "Max",
    value: "max_value",
  },
  {
    label: "Min",
    value: "min_value",
  },
  {
    label: "Average",
    value: "avg_value",
  },
];

const findLocationByIndex = (locations, index) =>
  locations.find((location) => location.location_index === index) || "";

const createDataset = (
  data,
  statistic,
  seriesName,
  units,
  borderColor,
  axisId
) => {
  if (!data.length) {
    return null;
  }
  return {
    data: data.map((item) => ({
      x: item.collect_date,
      y: item[statistic.value],
    })),
    yAxisID: axisId,
    units,
    borderWidth: 2,
    pointRadius: 0,
    label: `${statistic.label} ${seriesName}: ${data[0].location_id} - ${data[0].location_name}`,
    borderColor,
    backgroundColor: lighten(borderColor, 0.2),
    showLine: true,
  };
};

const queryOptions = {
  enabled: false,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

export const useTemperatureVsFlow = () => {
  const fetchDataForGraph = async (filterValues) => {
    try {
      const dataStreamTemp = await getChartData(
        filterValues?.streamTempLocation?.location_index ?? 0,
        2344,
        filterValues.startDate,
        filterValues.endDate
      );

      const dataAirTemp = await getChartData(
        filterValues?.airTempLocation?.location_index ?? 0,
        2296,
        filterValues.startDate,
        filterValues.endDate
      );

      const dataStreamFlow = await getChartData(
        filterValues?.streamFlowLocation?.location_index ?? 0,
        2346,
        filterValues.startDate,
        filterValues.endDate
      );

      const streamTempStatsCount = filterValues.streamTempStatistics.length;
      const airTempStatsCount = filterValues.airTempStatistics.length;

      const datasets = [
        ...(dataStreamTemp.length
          ? filterValues.streamTempStatistics.map((statistic, i) =>
              createDataset(
                dataStreamTemp,
                statistic,
                "Stream Temp",
                dataStreamTemp[0].units,
                COLOR_PALETTES.SCHEME_10_TABLEAU_COLORS[i],
                "yL"
              )
            )
          : []),
        ...(dataAirTemp.length
          ? filterValues.airTempStatistics.map((statistic, i) =>
              createDataset(
                dataAirTemp,
                statistic,
                "Air Temp",
                dataAirTemp[0].units,
                COLOR_PALETTES.SCHEME_10_TABLEAU_COLORS[
                  i + streamTempStatsCount
                ],
                "yL"
              )
            )
          : []),
        ...(dataStreamFlow.length
          ? filterValues.streamFlowStatistics.map((statistic, i) =>
              createDataset(
                dataStreamFlow,
                statistic,
                "Stream Flow",
                dataStreamFlow[0].units,
                COLOR_PALETTES.SCHEME_10_TABLEAU_COLORS[
                  i + streamTempStatsCount + airTempStatsCount
                ],
                "yR"
              )
            )
          : []),
      ];

      return {
        yLLabel: "Temperature (°F)",
        yRLabel: "Flow (CFS)",
        datasets,
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const [filterValues, setFilterValues] = useState({
    startDate: new Date(new Date().getFullYear() - 2, 9, 1),
    endDate: new Date(),
    streamTempStatistics: [],
    streamTempLocation: "",
    airTempStatistics: [],
    airTempLocation: "",
    streamFlowStatistics: [],
    streamFlowLocation: "",
  });

  const locationAssociations = useDataQuery(
    "location-associations-for-temp-flow-analysis",
    "location-associations-for-temp-flow-analysis"
  );

  const streamTempLocations = useDataQuery(
    "stream-temp-locations-for-temp-flow-analysis",
    "stream-temp-locations-for-temp-flow-analysis"
  );

  const airTempLocations = useDataQuery(
    "air-temp-locations-for-temp-flow-analysis",
    "air-temp-locations-for-temp-flow-analysis"
  );

  const streamFlowLocations = useDataQuery(
    "stream-flow-locations-for-temp-flow-analysis",
    "stream-flow-locations-for-temp-flow-analysis"
  );

  const findLocationAssociations = useCallback(
    (streamTempLocationIndex) =>
      locationAssociations.data.find(
        (association) =>
          association.stream_temp_location_index === streamTempLocationIndex
      ),
    [locationAssociations]
  );

  const handleFilterChange = useCallback(
    (name, value) => {
      setFilterValues((prevState) => {
        const newFilterValues = { ...prevState, [name]: value };

        if (name === "streamTempLocation" && value !== null) {
          const associations = findLocationAssociations(value.location_index);
          newFilterValues["airTempLocation"] = findLocationByIndex(
            airTempLocations.data,
            associations.air_temp_location_index
          );
          newFilterValues["streamFlowLocation"] = findLocationByIndex(
            streamFlowLocations.data,
            associations.stream_flow_location_index
          );
        }

        return newFilterValues;
      });
    },
    [airTempLocations, streamFlowLocations, findLocationAssociations]
  );

  //dw_admins;
  return {
    data: {
      streamTempLocations: streamTempLocations,
      airTempLocations: airTempLocations,
      streamFlowLocations: streamFlowLocations,
      graph: useQuery(
        ["ts-daily-graph-data"],
        () => fetchDataForGraph(filterValues),
        queryOptions
      ),
      statistics,
    },
    filterValues,
    setFilterValues,
    handleFilterChange,
  };
};
