import React, { useRef } from "react";

import { Helmet } from "react-helmet-async";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { useSiteStatistics } from "./useSiteStatistics";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Table from "../../../components/Table";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import LocationsFilter from "../../../components/filters/LocationsFilter";
import ParametersFilter from "../../../components/filters/ParametersFilter";
import { StatisticsFilter } from "../TimeSeriesComparisons/TimeSeriesComparisons";
import SiteStatisticsBoxPlot from "../../../components/graphs/SiteStatisticsBoxPlot";
import SiteStatisticsBarChart from "../../../components/graphs/SiteStatisticsBarChart";
import {
  DAILY_STATISTIC_OPTIONS,
  PARAMETERS_WITH_MIN_MAX,
} from "../TimeSeriesComparisons/constants";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Accordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const AxisFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const BoxPlotContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

function isSubmitDisabled(filterValues) {
  const { location, parameter, startDate, endDate } = filterValues;

  const hasLocation = location?.length;
  const hasParameter = parameter?.length;
  const hasStartDate = !!startDate;
  const hasEndDate = !!endDate;

  return !hasLocation || !hasParameter || !hasStartDate || !hasEndDate;
}

function SiteStatistics() {
  const saveBarChartRef = useRef(null);
  const saveBoxPlotRef = useRef(null);

  const {
    data,
    handleSubmit,
    isSubmitDirty,
    filterValues,
    handleFilterChange,
  } = useSiteStatistics();

  const handleSelectLocation = (locations) => {
    handleFilterChange(
      "location",
      locations?.map((l) => l.location_index)
    );
  };

  const handleSelectParameter = (parameter) => {
    handleFilterChange(
      "parameter",
      parameter?.map((p) => p.parameter_index)
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Site Statistics Page" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Statistics
      </Typography>
      <Divider my={6} />
      <Typography variant="h4" gutterBottom>
        Getting Started
      </Typography>
      <Typography mb={4}>
        Use this tool to make comparisons for a single parameter and location
        over time.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="scatter-plot"
              id="scatter-plot"
            >
              <Typography variant="h4" ml={10}>
                Time Period Filters
              </Typography>
            </AccordionSummary>
            <Box mx={2}>
              <AccordionDetails>
                <FilterContainer>
                  <FilterRow>
                    <FormControl component="fieldset">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          inputVariant="outlined"
                          disableToolbar
                          label={"Start Date"}
                          format="MM/dd/yyyy"
                          id="start-date-picker"
                          value={filterValues.startDate}
                          onChange={(date) =>
                            handleFilterChange("startDate", date)
                          }
                          InputAdornmentProps={{
                            "aria-label": "change start date",
                          }}
                          size="medium"
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl component="fieldset">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          inputVariant="outlined"
                          disableToolbar
                          format="MM/dd/yyyy"
                          label={"End Date"}
                          id="end-date-picker"
                          value={filterValues.endDate}
                          onChange={(date) =>
                            handleFilterChange("endDate", date)
                          }
                          InputAdornmentProps={{
                            "aria-label": "change end date",
                          }}
                          size="medium"
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </FilterRow>
                </FilterContainer>

                <FilterContainer>
                  <Typography variant="h4" mb={4}>
                    Parameter, Location, and Statistic Filters
                  </Typography>
                  <AxisFilterRow>
                    <ParametersFilter
                      defaultValue={filterValues?.parameter}
                      onSelect={handleSelectParameter}
                      isMultiSelect={false}
                      title="Parameter"
                    />
                    <LocationsFilter
                      onSelect={handleSelectLocation}
                      defaultFilterValues={{
                        parameters: filterValues?.parameter,
                        startDate: filterValues?.startDate,
                        endDate: filterValues?.endDate,
                      }}
                      defaultValue={filterValues?.location}
                      isMultiSelect={false}
                      title={"Location"}
                    />
                    <StatisticsFilter
                      disabled={
                        !filterValues?.parameter?.some((p) =>
                          PARAMETERS_WITH_MIN_MAX.includes(p)
                        )
                      }
                      label={"Daily Statistic"}
                      helperText={
                        "The daily statistic to average for each month. i.e. Monthly average of daily max values. Min and Max are only available for air temperature, stream temperature, and stream flow."
                      }
                      options={DAILY_STATISTIC_OPTIONS}
                      onChange={(e) =>
                        handleFilterChange("statistic", e.target.value)
                      }
                      value={filterValues?.statistic}
                    />
                  </AxisFilterRow>
                </FilterContainer>

                <SubmitGrid item container>
                  <Grid item style={{ width: "calc(100% - 162px)" }}>
                    {!isSubmitDirty && (
                      <Alert severity="info">
                        After selecting your inputs, click the 'Submit' button
                        to load an interactive bar char, table, and box plot for
                        comparison.
                      </Alert>
                    )}
                    {data?.barChart?.data?.length === 0 && isSubmitDirty && (
                      <Alert severity="warning">No data available for</Alert>
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large"
                      style={{ marginLeft: "10px" }}
                      disabled={isSubmitDisabled(filterValues)}
                    >
                      Submit
                    </Button>
                  </Grid>
                </SubmitGrid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      </Grid>

      {isSubmitDirty && (
        <>
          {data.barChart.data && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="bar-chart"
                id="bar-chart"
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h4" ml={2}>
                      {data?.barChart?.ui?.title}
                    </Typography>
                    <Typography variant="body1" ml={2} mt={2}>
                      {data?.barChart?.ui?.subtitle}
                    </Typography>
                  </Box>
                  <SaveGraphButton
                    ref={saveBarChartRef}
                    title="Site Statistics Bar Chart"
                    disabled={data.barChart.isLoading || !data.barChart.data}
                  />
                </Box>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <SiteStatisticsBarChart
                    data={data.barChart.data || []}
                    error={data.barChart.error}
                    isLoading={data.barChart.isLoading}
                    ref={saveBarChartRef}
                    yLLabel={data.barChart.ui.yLLabel}
                  />
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
          {data.barChart.data && !data.barChart.isLoading && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {data?.barChart?.ui?.title}
                </Typography>
                <Typography variant="body1" ml={2} mt={2}>
                  {data?.barChart?.ui?.subtitle}
                </Typography>
              </AccordionSummary>
              <Box mx={3}>
                <AccordionDetails>
                  <TableWrapper>
                    <Table
                      label="Monthly Averages Over Time Table"
                      columns={data.barChart.tableColumns}
                      data={data?.barChart?.tableData}
                      height="590px"
                    />
                  </TableWrapper>
                </AccordionDetails>
              </Box>
            </Accordion>
          )}

          <div ref={saveBoxPlotRef}>
            {data.boxPlot.data && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="box-plot"
                  id="box-plot"
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography variant="h4" ml={2}>
                      Annual Statistics Box Plot
                    </Typography>
                    <SaveGraphButton
                      ref={saveBoxPlotRef}
                      title="Site Statistics Box Plot"
                      disabled={data.boxPlot.isLoading || !data.boxPlot.data}
                    />
                  </Box>
                </AccordionSummary>
                <Box mx={3}>
                  <AccordionDetails>
                    <BoxPlotContainer>
                      <SiteStatisticsBoxPlot
                        data={data.boxPlot.data}
                        error={data.boxPlot.error}
                        legendItems={data.boxPlot.ui.legendItems}
                      />
                    </BoxPlotContainer>
                  </AccordionDetails>
                </Box>
              </Accordion>
            )}
          </div>
          {/*{data.boxPlot.data && !data.boxPlot.isLoading && (*/}
          {/*  <Accordion defaultExpanded>*/}
          {/*    <AccordionSummary*/}
          {/*      expandIcon={<ExpandMoreIcon />}*/}
          {/*      aria-controls="table-content"*/}
          {/*      id="table-header"*/}
          {/*    >*/}
          {/*      <Typography variant="h4" ml={2}>*/}
          {/*        Table*/}
          {/*      </Typography>*/}
          {/*    </AccordionSummary>*/}
          {/*    <Box mx={3}>*/}
          {/*      <AccordionDetails>*/}
          {/*        <TableWrapper>*/}
          {/*          <Table*/}
          {/*            label="Parameter Comparison Table"*/}
          {/*            columns={tableColumns}*/}
          {/*            data={[*/}
          {/*              ...[].concat.apply([], data?.boxPlot?.data?.leftAxis),*/}
          {/*            ]}*/}
          {/*            height="590px"*/}
          {/*          />*/}
          {/*        </TableWrapper>*/}
          {/*      </AccordionDetails>*/}
          {/*    </Box>*/}
          {/*  </Accordion>*/}
          {/*)}*/}
        </>
      )}
    </React.Fragment>
  );
}

export default SiteStatistics;
