import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import styled from "styled-components/macro";

const Container = styled.pre`
  background: rgba(0, 0, 0, 0.6);
  color: #fff;

  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 2;
  //display: none;
`;

const UpdateFrequencyPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const rows = [
    {
      source: "CO Data Sharing Network",
      updateFrequency: "Daily data uploads",
    },
    {
      source: "Colorado DWR/Colorado DWR (USGS)",
      updateFrequency:
        "Daily: average, minimum, and maximum values are calculated daily from 15-minute data imports",
    },
    {
      source: "Denver Water LIMS",
      updateFrequency:
        "Refreshed and uploaded at the end of each month, data can have up to a 30-day delay for analysis and review post collection",
    },
    { source: "National WQ Portal", updateFrequency: "Daily data uploads" },
  ];

  const cellStyle = {
    wordBreak: "break-word",
    fontSize: "0.8rem",
    color: "white",
  };

  const tableContainerStyle = {
    backgroundColor: "transparent",
    maxWidth: 400,
    margin: "auto",
    textWrap: "wrap",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box mt={2} style={{ width: "100%" }}>
        <Button
          variant="outlined"
          fullWidth
          size="small"
          startIcon={<Info />}
          onClick={handleClick}
        >
          Update Frequency
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Container>
          <TableContainer component={Paper} style={tableContainerStyle}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ ...cellStyle, fontWeight: 700 }}>
                    Source
                  </TableCell>
                  <TableCell style={{ ...cellStyle, fontWeight: 700 }}>
                    Update Frequency
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" style={cellStyle}>
                      {row.source}
                    </TableCell>
                    <TableCell style={cellStyle}>
                      {row.updateFrequency}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Popover>
    </>
  );
};

export default UpdateFrequencyPopover;
